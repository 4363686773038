import { useEffect, useState } from 'react';
import { useTarget, useTargets } from '../../lib/hooks/use-targets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import List from '../../components/ui/list/List';
import MissingTarget from '../../components/targets/MissingTarget';
import Target from '../../components/targets/Target';
import SetTarget, { SetTargetForm } from '../../components/targets/SetTarget';
import { updateTarget } from '../../lib/db/targets';
import Content from '../../components/layout/Content';
import TopBar from '../../components/layout/TopBar';
import useStore from '../../lib/hooks/use-store';
import Alert from '../../components/ui/Alert';
import { useUser } from '../../lib/hooks/use-user';
import Button from '../../components/ui/Button';
import Loader from '../../components/ui/Loader';
import { ConfirmTons } from '../../components/forms/ConfirmTons';
import SearchAndSort from '../../components/ui/SearchAndSort';
import { UpdateTargetData } from '../../../../global';
import { Timestamp } from 'firebase/firestore';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';

//Months
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Targets = () => {
  const checkAccess = useCheckAccess();
  const { user } = useUser();
  const [state, dispatch] = useStore();
  const {
    error: targetsError,
    targets,
    loading: targetsLoading,
    reset,
  } = useTargets({
    month: state.date.month() + 1,
    year: state.date.year(),
    pageSize: 0,
    targetType: state.targetStatus.includes('tpcp')
      ? 'distributor'
      : state.targetStatus.includes('fsa')
      ? 'fsa'
      : 'tae',
    cluster: state.selectedCluster !== '' ? state.selectedCluster : '',
  });
  const {
    target,
    loading: targetLoading,
    update,
  } = useTarget(`${state.selectedCluster}_${state.date.format('YYYY_M')}`);
  const [showSetTarget, setShowSetTarget] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showTargetsAlert, setShowTargetsAlert] = useState<boolean>(false);
  const [visitTarget, setVisitTarget] = useState<number>(0);
  const [cementTarget, setCementTarget] = useState<number>(0);
  const [mortarTarget, setMortarTarget] = useState<number>(0);
  const [siteVisitTarget, setSiteVisitTarget] = useState<number>(0);
  const [leadAmountTarget, setLeadAmountTarget] = useState<number>(0);
  const [leadCementTarget, setLeadCementTarget] = useState<number>(0);
  const [leadMortarTarget, setLeadMortarTarget] = useState<number>(0);
  const [showTonsConfirmation, setShowTonsConfirmation] = useState(true);

  useEffect(() => {
    if (user && user.clusters && state.selectedCluster === '') {
      dispatch('SET_SELECTED_CLUSTER', user.clusters[0].id);
    }
  }, [dispatch, state.selectedCluster, user]);

  useEffect(() => {
    if (targetsError) {
      setShowTargetsAlert(true);
    } else if (showTargetsAlert) {
      setShowTargetsAlert(false);
    }
    return () => {
      setShowTargetsAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetsError]);

  //load fsa targets
  useEffect(() => {
    if (targetsLoading) return;

    let newVisitTarget: number = 0;
    let newCementTarget: number = 0;
    let newMortarTarget: number = 0;
    let newSiteVisitTarget: number = 0;
    let newLeadCementTarget: number = 0;
    let newLeadMortarTarget: number = 0;
    let newLeadAmountTarget: number = 0;

    for (const target of targets) {
      newVisitTarget += target.visitTarget ? +target.visitTarget : 0;
      newCementTarget += target.cementTarget ? +target.cementTarget : 0;
      newMortarTarget += target.mortarTarget ? +target.mortarTarget : 0;
      newSiteVisitTarget += target.siteVisitTarget
        ? +target.siteVisitTarget
        : 0;
      newLeadCementTarget += target.leadCementTarget
        ? +target.leadCementTarget
        : 0;
      newLeadMortarTarget += target.leadMortarTarget
        ? +target.leadMortarTarget
        : 0;
      newLeadAmountTarget += target.leadAmountTarget
        ? +target.leadAmountTarget
        : 0;
    }

    setVisitTarget(newVisitTarget);
    setCementTarget(newCementTarget);
    setMortarTarget(newMortarTarget);
    setSiteVisitTarget(newSiteVisitTarget);
    setLeadCementTarget(newLeadCementTarget);
    setLeadMortarTarget(newLeadMortarTarget);
    setLeadAmountTarget(newLeadAmountTarget);
  }, [targets, targetsLoading, state.date, state.targetStatus]);

  const onMonthChange = (direction) => {
    if (direction === 'add') {
      if (state.date.diff(dayjs().startOf('month'), 'month') >= 1) return;
      dispatch('ADD_MONTH', {});
    } else {
      if (state.date.subtract(1, 'month').isBefore('2022-05-01', 'month'))
        return;
      dispatch('SUBTRACT_MONTH', {});
    }
  };

  const handleStatusChange = (newStatus) => () => {
    dispatch('SET_TARGET_STATUS', newStatus);
  };

  const updateFSATarget = async (id: string, data: UpdateTargetData) => {
    setLoading(true);
    await updateTarget(id, {
      visitTarget: +data.visitTarget,
      cementTarget: +data.cementTarget,
      mortarTarget: +data.mortarTarget,
      lastEdit: Timestamp.now(),
    });
    reset();
    setLoading(false);
    setShowSetTarget(false);
  };

  const updateTAETarget = async (id: string, data: UpdateTargetData) => {
    setLoading(true);
    await updateTarget(id, {
      siteVisitTarget: +data.siteVisitTarget,
      leadCementTarget: +data.leadCementTarget,
      leadMortarTarget: +data.leadMortarTarget,
      leadAmountTarget: +data.leadAmountTarget,
      lastEdit: Timestamp.now(),
    });
    reset();
    setLoading(false);
    setShowSetTarget(false);
  };

  const updateDistributorTarget = async (
    id: string,
    data: UpdateTargetData
  ) => {
    setLoading(true);
    await updateTarget(id, {
      cementTarget: +data.cementTarget,
      mortarTarget: +data.mortarTarget,
      lastEdit: Timestamp.now(),
    });
    reset();
    setLoading(false);
    setShowSetTarget(false);
  };

  const handleSetTargetCluster = async (data: SetTargetForm) => {
    if (
      data.visitTarget >= 0 &&
      data.cementTarget >= 0 &&
      data.mortarTarget >= 0
    ) {
      try {
        await update({
          visitTarget: +data.visitTarget,
          cementTarget: +data.cementTarget,
          mortarTarget: +data.mortarTarget,
          lastEdit: Timestamp.now(),
        });
      } catch (e) {
        console.log(e);
      }
    }

    reset();
    setShowSetTarget(false);
  };

  const onClusterChange = (event) => {
    dispatch('SET_SELECTED_CLUSTER', event.target.value);
  };

  return (
    <Content topBar>
      <ConfirmTons
        title="Please remember all targets are in tons (Mortar and Cement)!"
        open={showTonsConfirmation}
        onConfirm={() => setShowTonsConfirmation(false)}
        setOpen={(open) => setShowTonsConfirmation(open)}
      />
      <Alert
        title="Error"
        message={targetsError && targetsError.message}
        open={showTargetsAlert}
        setOpen={(open) => setShowTargetsAlert(open)}
      />
      {/* TODO: block when loading <ButtonBottomRight onClick={reset} blocked={loading} arialabel="reload" icon={faSync} /> */}
      <TopBar className=" h-80">
        <div className="w-full grid grid-cols-12">
          <div className="col-span-12 grid grid-cols-12 h-20">
            <div
              className={
                'col-span-4 flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
                (state.targetStatus.includes('fsa')
                  ? ' border-b-4 border-lh-dark-blue font-semibold'
                  : '')
              }
              onClick={handleStatusChange(['fsa'])}
            >
              FSA
            </div>
            <div
              className={
                'col-span-4 flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
                (state.targetStatus.includes('tpcp')
                  ? ' border-b-4 border-lh-dark-blue font-semibold'
                  : '')
              }
              onClick={handleStatusChange(['tpcp'])}
            >
              Distributor
            </div>
            <div
              className={
                'col-span-4 flex justify-center content-center flex-col text-center flex-grow cursor-pointer text-lh-dark-blue text-xl font-medium border-b-2 border-lh-dark-blue h-full' +
                (state.targetStatus.includes('tae')
                  ? ' border-b-4 border-lh-dark-blue font-semibold'
                  : '')
              }
              onClick={handleStatusChange(['tae'])}
            >
              TAE
            </div>
          </div>
          {user && 'clusters' in user && user.clusters.length > 1 && (
            <SearchAndSort
              onCategoryChange={onClusterChange}
              selectedCategory={state.selectedCluster}
              categories={Object.fromEntries(
                user.clusters.map((cluster) => [cluster.id, cluster.name])
              )}
              displayCategory={true}
              className={'mt-4 col-span-12'}
            />
          )}
          <div className="w-full grid grid-cols-12 col-span-12">
            <div className="w-full grid col-span-12 grid-cols-12 justify-center mt-4">
              <button
                className={
                  'font-semibold md:col-span-1 md:col-start-5 col-span-1 col-start-4 text-center cursor-pointer ' +
                  (state.date
                    .subtract(1, 'month')
                    .isBefore('2022-05-01', 'month')
                    ? 'hidden'
                    : 'block')
                }
                onClick={() => onMonthChange('substract')}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={'font-semibold '}
                />
              </button>

              <p
                className={
                  'font-semibold align-middle text-center md:col-span-2 md:col-start-6 col-span-4 col-start-5 pt-1'
                }
              >
                {monthNames[state.date.month()] + ' ' + state.date.year()}
              </p>

              <button
                className={
                  'font-semibold md:col-span-1 md:col-start-8 col-span-1 col-start-9 text-center cursor-pointer ' +
                  (state.date.diff(dayjs().startOf('month'), 'month') >= 1
                    ? 'hidden'
                    : 'block')
                }
                onClick={() => onMonthChange('add')}
              >
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={'font-semibold'}
                />
              </button>
            </div>
            {target && !state.targetStatus.includes('tae') && (
              <div className="col-span-full text-center mt-2 bg-white">
                <table className="table-auto ml-auto mr-auto border-2">
                  <thead>
                    <tr className="border-2">
                      <th></th>
                      {state.targetStatus.includes('fsa') && (
                        <th className="p-3 border-l-2">Visits</th>
                      )}
                      <th className="p-3">Cement</th>
                      <th className="p-3">Mortar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-2">
                      <td className="p-2 border-r-2 font-bold">Cluster</td>
                      {state.targetStatus.includes('fsa') && (
                        <td>{target.visitTarget}</td>
                      )}
                      <td>{target.cementTarget}</td>
                      <td>{target.mortarTarget}</td>
                    </tr>
                    <tr>
                      <td className="p-2 border-r-2 font-bold">
                        {state.targetStatus.includes('fsa')
                          ? 'FSA'
                          : 'Distributor'}
                      </td>
                      {state.targetStatus.includes('fsa') && (
                        <td
                          className={
                            visitTarget > target.visitTarget
                              ? 'text-red-500'
                              : ''
                          }
                        >
                          {visitTarget}{' '}
                        </td>
                      )}
                      <td
                        className={
                          cementTarget > target.cementTarget
                            ? 'text-red-500'
                            : ''
                        }
                      >
                        {cementTarget}{' '}
                      </td>
                      <td
                        className={
                          mortarTarget > target.mortarTarget
                            ? 'text-red-500'
                            : ''
                        }
                      >
                        {mortarTarget}{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {checkAccess(['sop']) &&
                  !target.lockedFSA &&
                  state.targetStatus.includes('fsa') &&
                  !target.lastEdit &&
                  !target.locked && (
                    <Button
                      onClick={() => setShowSetTarget(true)}
                      text="Set cluster targets in tons"
                      color="green"
                      className="mt-2"
                    />
                  )}
                {checkAccess(['sop']) &&
                  state.targetStatus.includes('fsa') &&
                  target.lastEdit &&
                  !target.locked && (
                    <Button
                      onClick={() => setShowSetTarget(true)}
                      text="Edit cluster targets in tons"
                      color="green"
                      className="mt-2"
                    />
                  )}
                {checkAccess(['sop']) &&
                  state.targetStatus.includes('tpcp') &&
                  target.lastEdit &&
                  !target.locked && (
                    <Button
                      onClick={() => setShowSetTarget(true)}
                      text="Edit cluster targets in tons"
                      color="green"
                      className="mt-2"
                    />
                  )}
                {checkAccess(['sop']) &&
                  state.targetStatus.includes('tpcp') &&
                  !target.lastEdit &&
                  !target.locked && (
                    <Button
                      onClick={() => setShowSetTarget(true)}
                      text="Set cluster targets in tons"
                      color="green"
                      className="mt-2"
                      buttonDisabled={
                        !(cementTarget <= target.cementTarget) ||
                        !(mortarTarget <= target.mortarTarget)
                      }
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      </TopBar>
      <Loader show={loading || targetLoading || targetsLoading} />
      <SetTarget
        open={showSetTarget}
        cluster={state.selectedCluster}
        target={target}
        month={state.date.month()}
        year={state.date.year()}
        onSet={handleSetTargetCluster}
        setOpen={(open) => setShowSetTarget(open)}
        confirmText="Are you sure you want to set the cluster target."
        buttonText="Set cluster targets"
      />
      <List className="mt-80">
        {state.targetStatus.includes('fsa') &&
          target &&
          !target.locked &&
          targets &&
          targets.map((fsaTarget) => {
            if (fsaTarget && fsaTarget.fsa && !fsaTarget.locked) {
              return (
                <MissingTarget
                  type="fsa"
                  target={fsaTarget}
                  clusterTarget={target}
                  key={`${
                    fsaTarget.fsa.id
                  }_${state.date.year()}_${state.date.month()}`}
                  updateTarget={updateFSATarget}
                  currentSetTargets={{
                    visit: visitTarget,
                    cement: cementTarget,
                    mortar: mortarTarget,
                  }}
                />
              );
            } else {
              return '';
            }
          })}

        {state.targetStatus.includes('tpcp') &&
          target &&
          !target.locked &&
          targets &&
          targets.map((distributorTarget) => {
            if (
              distributorTarget &&
              distributorTarget.distributor &&
              !distributorTarget.locked
            ) {
              return (
                <MissingTarget
                  type="distributor"
                  target={distributorTarget}
                  clusterTarget={target}
                  key={`${
                    distributorTarget.distributor.id
                  }_${state.date.year()}_${state.date.month()}`}
                  updateTarget={updateDistributorTarget}
                  currentSetTargets={{
                    cement: cementTarget,
                    mortar: mortarTarget,
                  }}
                />
              );
            } else {
              return '';
            }
          })}

        {state.targetStatus.includes('tae') &&
          target &&
          !target.locked &&
          targets &&
          targets.map((taeTarget) => {
            if (taeTarget && taeTarget.tae && !taeTarget.locked) {
              return (
                <MissingTarget
                  type="tae"
                  target={taeTarget}
                  key={`${
                    taeTarget.tae.id
                  }_${state.date.year()}_${state.date.month()}`}
                  updateTarget={updateTAETarget}
                  currentSetTargets={{
                    visit: siteVisitTarget,
                    leadAmount: leadAmountTarget,
                    cement: leadCementTarget,
                    mortar: leadMortarTarget,
                  }}
                />
              );
            } else {
              return '';
            }
          })}
        {state.targetStatus.includes('fsa') &&
          target &&
          target.locked &&
          targets.map((t) => <Target type="fsa" target={t} key={t.id} />)}
        {state.targetStatus.includes('tpcp') &&
          target &&
          target.locked &&
          targets.map((t) => (
            <Target type="distributor" target={t} key={t.id} />
          ))}
        {state.targetStatus.includes('tae') &&
          target &&
          target.locked &&
          targets.map((t) => <Target type="tae" target={t} key={t.id} />)}
      </List>
    </Content>
  );
};

export default Targets;
