import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { Confirm } from '../forms/Confirm';
import Input from '../forms/Input';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

export type InitialInventoryModalForm = {
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
  watershield: number;
  tempProduct1: number;
  tempProduct2: number;
  tempProduct3: number;
  tempProduct4: number;
};

type InitialInventoryModalProps = {
  open: boolean;
  onSet: SubmitHandler<InitialInventoryModalForm>;
  setOpen: (boolean) => void;
};

const InitialInventoryModal = ({
  open,
  onSet,
  setOpen,
}: InitialInventoryModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<InitialInventoryModalForm>({ mode: 'all' });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);
    handleSubmit(onSet)();
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  const inventoryOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Inventory needs to be 0 or higher!',
    },
    required: true,
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Confirm
        title="Are you sure you want to set this initial inventory in tons?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form name="initialInventoryForm" className="flex flex-col">
        <label className="font-bold mb-2">Classic in tons: </label>
        <Input
          type="number"
          name="classic"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.classic}
          className="mb-2"
        />
        <label className="font-bold mb-2">Supaset in tons: </label>
        <Input
          type="number"
          name="supaset"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.supaset}
          className="mb-2"
        />
        <label className="font-bold mb-2">Supafix C0 in tons: </label>
        <Input
          type="number"
          name="supafixc0"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.supafixc0}
          className="mb-2"
        />
        <label className="font-bold mb-2">Supafix C1 in tons: </label>
        <Input
          type="number"
          name="supafixc1"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.supafixc1}
          className="mb-2"
        />
        <label className="font-bold mb-2">Watershield: </label>
        <Input
          type="number"
          name="watershield"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.watershield}
          className="mb-2"
        />
        {/* TempProduct1
        <label className="font-bold mb-2">TempProduct1: </label>
        <Input
          type="number"
          name="tempProduct1"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.tempProduct1}
          className="mb-2"
        />
        TempProduct1 */}
        {/* TempProduct2
        <label className="font-bold mb-2">TempProduct2: </label>
        <Input
          type="number"
          name="tempProduct2"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.tempProduct2}
          className="mb-2"
        />
        TempProduct2 */}
        {/* TempProduct3
        <label className="font-bold mb-2">TempProduct3: </label>
        <Input
          type="number"
          name="tempProduct3"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.tempProduct3}
          className="mb-2"
        />
        TempProduct3 */}
        {/* TempProduct4
        <label className="font-bold mb-2">TempProduct4: </label>
        <Input
          type="number"
          name="tempProduct4"
          placeholder="0"
          register={register}
          options={inventoryOptions}
          error={errors.tempProduct4}
          className="mb-2"
        />
        TempProduct4 */}
      </form>
      <Button
        text="Set inventory in tons"
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
        buttonDisabled={!isValid}
      />
    </Modal>
  );
};

export default InitialInventoryModal;
