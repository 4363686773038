import { useEffect, useState } from 'react';
import React from 'react';
import useStore from '../../lib/hooks/use-store';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import Content from '../../components/layout/Content';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/ui/Alert';
import { useUser } from '../../lib/hooks/use-user';
import { ConfirmTOS } from '../../components/forms/ConfirmTOS';
import { useSignOut } from '../../lib/auth/use-signout';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { analytics, firestore } from '../../lib/utils/firebase';
import dayjs from 'dayjs';
import { logEvent } from 'firebase/analytics';
import {
  calculateCementApp,
  calculateCementChatbot,
  calculateDirectSalesCement,
  calculateDirectSalesMortar,
  calculateLeadAmount,
  calculateLeadVolume,
  calculateMortarApp,
  calculateMortarChatbot,
  calculateSiteVisits,
  calculateVisits,
  getRealtimeLeadsData,
  getRealtimeOrderData,
} from '../../lib/utils/dashboard/helper';
import { set } from 'ol/transform';

const Home = React.memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, dispatch] = useStore({ shouldListen: false });
  const navigate = useNavigate();
  const signOut = useSignOut();
  const checkAccess = useCheckAccess();
  const { user, error, update } = useUser();
  const date = new Date();
  const hours = date.getHours();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pending, setPending] = useState<number>(0);
  const [acceptedOrders, setAcceptedOrders] = useState<number>(0);
  const [fulfilled, setFulfilled] = useState<number>(0);
  const [rejectedOrders, setRejectedOrders] = useState<number>(0);
  const [cementThisMonth, setCementThisMonth] = useState<number>(0);
  const [cementLastMonth, setCementLastMonth] = useState<number>(0);
  const [mortarThisMonth, setMortarThisMonth] = useState<number>(0);
  const [mortarLastMonth, setMortarLastMonth] = useState<number>(0);
  const [targetCement, setTargetCement] = useState<number>(0);
  const [targetMortar, setTargetMortar] = useState<number>(0);
  const [chatbotVolumeThisMonth, setChatbotVolumeThisMonth] =
    useState<number>(0);
  const [appVolumeThisMonth, setAppVolumeThisMonth] = useState<number>(0);
  const [visitsThisMonth, setVisitsThisMonth] = useState<number>(0);
  const [visitsLastMonth, setVisitsLastMonth] = useState<number>(0);
  const [targetVisits, setTargetVisits] = useState<number>(0);
  const [leadsThisMonth, setLeadsThisMonth] = useState<number>(0);
  const [leadsLastMonth, setLeadsLastMonth] = useState<number>(0);
  const [leadAmountTarget, setLeadAmountTarget] = useState<number>(0);
  const [leadVolumeCementTarget, setLeadVolumeCementTarget] =
    useState<number>(0);
  const [leadVolumeMortarTarget, setLeadVolumeMortarTarget] =
    useState<number>(0);
  const [siteVisitTarget, setSiteVisitTarget] = useState<number>(0);
  const [leadVolumeThisMonth, setLeadVolumeThisMonth] = useState<number>(0);
  const [leadVolumeLastMonth, setLeadVolumeLastMonth] = useState<number>(0);
  const [siteVisitsThisMonth, setSiteVisitsThisMonth] = useState<number>(0);
  const [siteVisitsLastMonth, setSiteVisitsLastMonth] = useState<number>(0);
  const [showFullLastMonth, setShowFullLastMonth] = useState<boolean>(false);
  const [amountOfCalculations, setAmountOfCalculations] = useState<number>(0);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const goToOrders = (status: string) => {
    if (!checkAccess(['tae'])) {
      dispatch('SET_STATUS', [status]);
      navigate(`/orders`);
    } else if (checkAccess(['tae'])) {
      dispatch('SET_STATUS_LEAD', [status]);
      navigate(`/leads`);
    }
  };

  const goToTargets = () => {
    navigate(`/targets`);
  };

  const optionsOrderVolume = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: '#1d4370',
        },
      },
    },
    maintainAspectRatio: false,
    indexAxis: 'y',
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          const data = tooltipItem.chart.data;
          const datasetIndex = tooltipItem.datasetIndex;
          const index = tooltipItem.dataIndex;
          const datasetLabel = data.datasets[datasetIndex].label || '';
          // You can use two type values.
          // `data.originalData` is raw values,
          // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
          const originalValue = data.originalData[datasetIndex][index];
          const rateValue = data.calculatedData[datasetIndex][index];

          return `${datasetLabel}: ${rateValue}% (raw ${originalValue})`;
        },
      },
    },
    plugins: {
      stacked100: { enable: true, replaceTooltipLabel: false },
      title: {
        text: 'Chatbot adoption',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        text: `Chatbot adoption rate: ${(
          (chatbotVolumeThisMonth /
            (chatbotVolumeThisMonth + appVolumeThisMonth)) *
          100
        ).toFixed(2)}%`,
      },
      legend: {
        display: true,
      },
    },
  };

  const dataOrderVolume = {
    labels: [''],
    datasets: [
      {
        label: 'Chatbot',
        data: [chatbotVolumeThisMonth ?? 0],
        backgroundColor: ['#94c12e'],
        borderColor: ['#94c12e'],
        borderWidth: 1,
      },
      {
        label: 'App',
        data: [appVolumeThisMonth ?? 0],
        backgroundColor: ['#256D9D'],
        borderColor: ['#256D9D'],
        borderWidth: 1,
      },
    ],
  };

  const optionsTargetsCement = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        ticks: {
          color: '#1d4370',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly cement targets (tons)',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        text: `${cementThisMonth >= cementLastMonth ? '+' : '-'}${Math.abs(
          Math.round(
            ((cementThisMonth - cementLastMonth) / cementLastMonth) * 100
          )
        )}% vs. SPLM`,
      },
    },
  };

  const dataCementTargets = {
    labels: ['Target', 'Actual', 'SPLM'],
    datasets: [
      {
        data: [targetCement ?? 0, cementThisMonth ?? 0, cementLastMonth ?? 0],
        backgroundColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderColor: ['#94c12e', '#256D9D', '#40B4EA'],
      },
    ],
  };

  const optionsTargetsMortar = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        ticks: {
          color: '#1d4370',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly mortar targets (tons)',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        text: `${mortarThisMonth >= mortarLastMonth ? '+' : '-'}${Math.abs(
          Math.round(
            ((mortarThisMonth - mortarLastMonth) / mortarLastMonth) * 100
          )
        )}% vs. SPLM`,
      },
    },
  };

  const dataMortarTargets = {
    labels: ['Target', 'Actual', 'SPLM'],
    datasets: [
      {
        data: [targetMortar ?? 0, mortarThisMonth ?? 0, mortarLastMonth ?? 0],
        backgroundColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderWidth: 1,
      },
    ],
  };

  const optionsTargetsVisits = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    subtitle: {
      display: true,
      text: 'Monthly visits targets',
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        ticks: {
          color: '#1d4370',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly visits targets',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        // calculate the percentage change from visitsThisMonth to visitsLastMonth
        text: `${visitsThisMonth >= visitsLastMonth ? '+' : '-'}${Math.abs(
          Math.round(
            visitsLastMonth === 0
              ? 0
              : ((visitsThisMonth - visitsLastMonth) / visitsLastMonth) * 100
          )
        )}% vs. SPLM`,
      },
    },
  };

  const dataVisitsTargets = {
    labels: ['Target', 'Actual', 'SPLM'],
    datasets: [
      {
        data: [targetVisits, visitsThisMonth, visitsLastMonth],
        backgroundColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderWidth: 1,
      },
    ],
  };

  const optionsSiteVisitsTargets = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        ticks: {
          color: '#1d4370',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly site visits targets',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        text: `${
          siteVisitsThisMonth >= siteVisitsLastMonth ? '+' : '-'
        }${Math.abs(
          Math.round(
            ((siteVisitsThisMonth - siteVisitsLastMonth) /
              siteVisitsLastMonth) *
              100
          )
        )}% vs. SPLM`,
      },
    },
  };

  const dataSiteVisitsTargets = {
    labels: ['Target', 'Actual', 'SPLM'],
    datasets: [
      {
        data: [siteVisitTarget, siteVisitsThisMonth, siteVisitsLastMonth],
        backgroundColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderWidth: 1,
      },
    ],
  };

  const optionsLeadsTargets = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        ticks: {
          color: '#1d4370',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly lead targets',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        text: `${leadsThisMonth >= leadsLastMonth ? '+' : '-'}${Math.abs(
          Math.round(((leadsThisMonth - leadsLastMonth) / leadsLastMonth) * 100)
        )}% vs. SPLM`,
      },
    },
  };

  const dataLeadsTargets = {
    labels: ['Target', 'Actual', 'SPLM'],
    datasets: [
      {
        data: [leadAmountTarget, leadsThisMonth, leadsLastMonth],
        backgroundColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderWidth: 1,
      },
    ],
  };

  const optionsLeadsVolumeTargets = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    labels: {
      display: false,
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: '#1d4370',
        },
      },
      y: {
        ticks: {
          color: '#1d4370',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        text: 'Monthly leads volume targets',
        display: true,
        color: '#1d4370',
      },
      subtitle: {
        display: true,
        text: `${
          leadVolumeThisMonth >= leadVolumeLastMonth ? '+' : '-'
        }${Math.abs(
          Math.round(
            ((leadVolumeThisMonth - leadVolumeLastMonth) /
              leadVolumeLastMonth) *
              100
          )
        )}% vs. SPLM`,
      },
    },
  };

  const dataLeadsVolumeTargets = {
    labels: ['Target', 'Actual', 'SPLM'],
    datasets: [
      {
        data: [
          leadVolumeMortarTarget + leadVolumeCementTarget,
          leadVolumeThisMonth,
          leadVolumeLastMonth,
        ],
        backgroundColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderColor: ['#94c12e', '#256D9D', '#40B4EA'],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    if (user && !user.appTOSAccepted) {
      setShowConfirmation(true);
    }
  }, [user]);

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    update({ appTOSAccepted: true });
  };

  const handleOnCancel = async () => {
    setShowConfirmation(false);
    await signOut();
    navigate('/', { replace: true });
  };

  const getFSA = async (showFullLastMonth: boolean) => {
    await getRealtimeOrderData(
      user,
      setPending,
      setAcceptedOrders,
      setFulfilled,
      setRejectedOrders
    );

    const collection2 = collection(firestore, 'analytics');

    const q4 = query(
      collection2,
      where('type', '==', 'fsa'),
      where('fsa.id', '==', user.id),
      where('date', '>=', dayjs().startOf('month').toDate()),
      where('date', '<=', dayjs().endOf('month').toDate())
    );

    const documents = await getDocs(q4);
    const thisMonth = documents.docs.map((doc) => doc.data());

    const fullMonthQuery = showFullLastMonth
      ? dayjs().endOf('month').subtract(1, 'month').toDate()
      : dayjs().endOf('day').subtract(1, 'month').toDate();

    const q5 = query(
      collection2,
      where('type', '==', 'fsa'),
      where('fsa.id', '==', user.id),
      where(
        'date',
        '>=',
        dayjs().startOf('month').subtract(1, 'month').toDate()
      ),
      where('date', '<=', fullMonthQuery)
    );

    const documents2 = await getDocs(q5);
    const lastMonth = documents2.docs.map((doc) => doc.data());

    const cementVolumeAppThisMonth = calculateCementApp(thisMonth);
    const cementVolumeWhatsappThisMonth = calculateCementChatbot(thisMonth);
    const cementVolumeAppLastMonth = calculateCementApp(lastMonth);
    const cementVolumeWhatsappLastMonth = calculateCementChatbot(lastMonth);

    const mortarVolumeAppThisMonth = calculateMortarApp(thisMonth);
    const mortarVolumeWhatsappThisMonth = calculateMortarChatbot(thisMonth);
    const mortarVolumeAppLastMonth = calculateMortarApp(lastMonth);
    const mortarVolumeWhatsappLastMonth = calculateMortarChatbot(lastMonth);

    const visitsThisMonth = calculateVisits(thisMonth);
    const visitsLastMonth = calculateVisits(lastMonth);

    const targetCement = thisMonth[0].target.cementTarget;
    const targetMortar = thisMonth[0].target.mortarTarget;
    const targetVisits = thisMonth[0].target.visitTarget;

    setTargetCement(targetCement);
    setTargetMortar(targetMortar);

    setAppVolumeThisMonth(cementVolumeAppThisMonth);
    setChatbotVolumeThisMonth(cementVolumeWhatsappThisMonth);

    setCementThisMonth(
      cementVolumeAppThisMonth + cementVolumeWhatsappThisMonth
    );
    setCementLastMonth(
      cementVolumeAppLastMonth + cementVolumeWhatsappLastMonth
    );
    setMortarThisMonth(
      mortarVolumeAppThisMonth + mortarVolumeWhatsappThisMonth
    );
    setMortarLastMonth(
      mortarVolumeAppLastMonth + mortarVolumeWhatsappLastMonth
    );

    setVisitsThisMonth(visitsThisMonth);
    setVisitsLastMonth(visitsLastMonth);

    setTargetVisits(targetVisits);
  };

  async function getTAE(showFullLastMonth: boolean) {
    getRealtimeLeadsData(user, setPending, setFulfilled);

    const analyticsCollection = collection(firestore, 'analytics');

    const q4 = query(
      analyticsCollection,
      where('type', '==', 'tae'),
      where('tae.id', '==', user.id),
      where('date', '>=', dayjs().startOf('month').toDate()),
      where('date', '<=', dayjs().endOf('month').toDate())
    );

    const documents = await getDocs(q4);
    const thisMonth = documents.docs.map((doc) => doc.data());

    const fullMonthQuery = showFullLastMonth
      ? dayjs().endOf('month').subtract(1, 'month').toDate()
      : dayjs().endOf('day').subtract(1, 'month').toDate();

    const q5 = query(
      analyticsCollection,
      where('type', '==', 'tae'),
      where('tae.id', '==', user.id),
      where(
        'date',
        '>=',
        dayjs().startOf('month').subtract(1, 'month').toDate()
      ),
      // current day of the month but last month
      where('date', '<=', fullMonthQuery)
    );

    const documents2 = await getDocs(q5);
    const lastMonth = documents2.docs.map((doc) => doc.data());

    const leadsAmountThisMonth = calculateLeadAmount(thisMonth);
    const leadsAmountLastMonth = calculateLeadAmount(lastMonth);
    const leadAmountVolumeThisMonth = calculateLeadVolume(thisMonth);
    const leadAmountVolumeLastMonth = calculateLeadVolume(lastMonth);
    const siteVisitsThisMonth = calculateSiteVisits(thisMonth);
    const siteVisitsLastMonth = calculateSiteVisits(lastMonth);
    const leadAmountTarget = thisMonth[0].target.leadAmountTarget;
    const leadVolumeCementTarget = thisMonth[0].target.leadCementTarget;
    const leadVolumeMortarTarget = thisMonth[0].target.leadMortarTarget;
    const siteVisitTarget = thisMonth[0].target.siteVisitTarget;

    setLeadsThisMonth(leadsAmountThisMonth);
    setLeadsLastMonth(leadsAmountLastMonth);
    setLeadVolumeThisMonth(leadAmountVolumeThisMonth);
    setLeadVolumeLastMonth(leadAmountVolumeLastMonth);
    setLeadAmountTarget(leadAmountTarget);
    setLeadVolumeCementTarget(leadVolumeCementTarget);
    setLeadVolumeMortarTarget(leadVolumeMortarTarget);
    setSiteVisitTarget(siteVisitTarget);
    setSiteVisitsThisMonth(siteVisitsThisMonth);
    setSiteVisitsLastMonth(siteVisitsLastMonth);
  }

  async function getSOP(fullLastMonth: boolean) {
    await getRealtimeOrderData(
      user,
      setPending,
      setAcceptedOrders,
      setFulfilled,
      setRejectedOrders
    );

    const analyticsCollection = collection(firestore, 'analytics');

    const q4 = query(
      analyticsCollection,
      where('type', '==', 'cluster'),
      where(
        'cluster.id',
        'in',
        user.clusters.map((cluster) => cluster.id)
      ),
      where('date', '>=', dayjs().startOf('month').toDate()),
      where('date', '<=', dayjs().endOf('month').toDate())
    );

    const documents = await getDocs(q4);
    const thisMonth = documents.docs.map((doc) => doc.data());

    const cementVolumeAppThisMonth = calculateCementApp(thisMonth);
    const cementVolumeWhatsappThisMonth = calculateCementChatbot(thisMonth);
    const mortarVolumeAppThisMonth = calculateMortarApp(thisMonth);
    const mortarVolumeWhatsappThisMonth = calculateMortarChatbot(thisMonth);
    const visitsThisMonth = calculateVisits(thisMonth);
    const leadVolumeThisMonth = calculateLeadVolume(thisMonth);
    const leadsThisMonth = calculateLeadAmount(thisMonth);

    if (amountOfCalculations === 0) {
      user.clusters.map((cluster) => {
        let alreadyCalculatedClusters = [];
        const target = thisMonth.find((item) => item.cluster.id === cluster.id);
        if (target) {
          setTargetCement((prev) => prev + target.target.cementTarget);
          setTargetMortar((prev) => prev + target.target.mortarTarget);
          setTargetVisits((prev) => prev + target.target.visitTarget);
          setSiteVisitTarget((prev) => prev + target.target.siteVisitTarget);
          setLeadAmountTarget((prev) => prev + target.target.leadAmountTarget);
          setLeadVolumeCementTarget(
            (prev) => prev + target.target.leadCementTarget
          );
          setLeadVolumeMortarTarget(
            (prev) => prev + target.target.leadMortarTarget
          );
        }
        alreadyCalculatedClusters.push(cluster.id);
      });
    }

    setAppVolumeThisMonth(cementVolumeAppThisMonth);
    setChatbotVolumeThisMonth(cementVolumeWhatsappThisMonth);
    setCementThisMonth(
      cementVolumeAppThisMonth + cementVolumeWhatsappThisMonth
    );
    setMortarThisMonth(
      mortarVolumeAppThisMonth + mortarVolumeWhatsappThisMonth
    );
    setVisitsThisMonth(visitsThisMonth);

    setSiteVisitsThisMonth(calculateSiteVisits(thisMonth));
    setLeadsThisMonth(leadsThisMonth);
    setLeadVolumeThisMonth(leadVolumeThisMonth);

    const fullMonthQuery = fullLastMonth
      ? dayjs().endOf('month').subtract(1, 'month').toDate()
      : dayjs().endOf('day').subtract(1, 'month').toDate();

    const q5 = query(
      analyticsCollection,
      where('type', '==', 'cluster'),
      where(
        'cluster.id',
        'in',
        user.clusters.map((cluster) => cluster.id)
      ),
      where(
        'date',
        '>=',
        dayjs().startOf('month').subtract(1, 'month').toDate()
      ),
      where('date', '<=', fullMonthQuery)
    );

    const documents2 = await getDocs(q5);
    const lastMonth = documents2.docs.map((doc) => doc.data());

    const cementVolumeAppLastMonth = calculateCementApp(lastMonth);
    const cementVolumeWhatsappLastMonth = calculateCementChatbot(lastMonth);
    const mortarVolumeAppLastMonth = calculateMortarApp(lastMonth);
    const mortarVolumeWhatsappLastMonth = calculateMortarChatbot(lastMonth);
    const visitsLastMonth = calculateVisits(lastMonth);
    const leadVolumeLastMonth = calculateLeadVolume(lastMonth);
    const leadsLastMonth = calculateLeadAmount(lastMonth);

    setCementLastMonth(
      cementVolumeAppLastMonth + cementVolumeWhatsappLastMonth
    );
    setMortarLastMonth(
      mortarVolumeAppLastMonth + mortarVolumeWhatsappLastMonth
    );
    setVisitsLastMonth(visitsLastMonth);
    setSiteVisitsLastMonth(calculateSiteVisits(lastMonth));
    setLeadsLastMonth(leadsLastMonth);
    setLeadVolumeLastMonth(leadVolumeLastMonth);

    setAmountOfCalculations((prev) => prev + 1);
  }

  async function getDistributor(showFullLastMonth: boolean) {
    await getRealtimeOrderData(
      user,
      setPending,
      setAcceptedOrders,
      setFulfilled,
      setRejectedOrders
    );

    const analyticsCollection = collection(firestore, 'analytics');

    const q4 = query(
      analyticsCollection,
      where('type', '==', 'distributor'),
      where('distributor.id', '==', user.id),
      where('date', '>=', dayjs().startOf('month').toDate()),
      where('date', '<=', dayjs().endOf('month').toDate())
    );

    const documents = await getDocs(q4);
    const thisMonth = documents.docs.map((doc) => doc.data());

    const cementVolumeAppThisMonth = calculateCementApp(thisMonth);
    const cementVolumeWhatsappThisMonth = calculateCementChatbot(thisMonth);
    const mortarVolumeAppThisMonth = calculateMortarApp(thisMonth);
    const mortarVolumeWhatsappThisMonth = calculateMortarChatbot(thisMonth);
    const volumeSalesCementThisMonth = calculateDirectSalesCement(thisMonth);
    const volumeSalesMortarThisMonth = calculateDirectSalesMortar(thisMonth);

    setAppVolumeThisMonth(cementVolumeAppThisMonth);
    setChatbotVolumeThisMonth(cementVolumeWhatsappThisMonth);

    const targetCement = thisMonth[0].target.cementTarget;
    const targetMortar = thisMonth[0].target.mortarTarget;

    setTargetCement(targetCement);
    setTargetMortar(targetMortar);

    setCementThisMonth(
      cementVolumeAppThisMonth +
        cementVolumeWhatsappThisMonth +
        volumeSalesCementThisMonth
    );

    setMortarThisMonth(
      mortarVolumeAppThisMonth +
        mortarVolumeWhatsappThisMonth +
        volumeSalesMortarThisMonth
    );

    const fullMonthQuery = showFullLastMonth
      ? dayjs().endOf('month').subtract(1, 'month').toDate()
      : dayjs().endOf('day').subtract(1, 'month').toDate();

    const q5 = query(
      analyticsCollection,
      where('type', '==', 'distributor'),
      where('distributor.id', '==', user.id),
      where(
        'date',
        '>=',
        dayjs().startOf('month').subtract(1, 'month').toDate()
      ),
      where('date', '<=', fullMonthQuery)
    );

    const documents2 = await getDocs(q5);

    const lastMonth = documents2.docs.map((doc) => doc.data());

    const cementVolumeAppLastMonth = calculateCementApp(lastMonth);
    const cementVolumeWhatsappLastMonth = calculateCementChatbot(lastMonth);
    const volumeSalesCementLastMonth = calculateDirectSalesCement(lastMonth);
    const mortarVolumeAppLastMonth = calculateMortarApp(lastMonth);
    const mortarVolumeWhatsappLastMonth = calculateMortarChatbot(lastMonth);
    const volumeSalesMortarLastMonth = calculateDirectSalesMortar(lastMonth);

    setCementLastMonth(
      cementVolumeAppLastMonth +
        cementVolumeWhatsappLastMonth +
        volumeSalesCementLastMonth
    );

    setMortarLastMonth(
      mortarVolumeAppLastMonth +
        mortarVolumeWhatsappLastMonth +
        volumeSalesMortarLastMonth
    );
  }

  useEffect(() => {
    if (user) {
      if (user.roles.includes('fsa')) {
        getFSA(showFullLastMonth);
      }

      if (user.roles.includes('tae')) {
        getTAE(showFullLastMonth);
      }

      if (user.roles.includes('sop') || user.roles.includes('fsm')) {
        getSOP(showFullLastMonth);
      }

      if (user.roles.includes('distributor')) {
        getDistributor(showFullLastMonth);
      }
    }
  }, [user, showFullLastMonth]);

  return (
    <Content>
      <ConfirmTOS
        title={
          'By using this application you consent to the collection and processing of your personal data in accordance with the Lafarge Africa privacy policy. '
        }
        link="https://www.lafarge.com.ng/privacy-policy"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
        }}
        confirmText="Accept"
        cancelText="Logout"
      />
      <Alert
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
        title="Error"
      />
      <div className="p-4">
        <div className="w-full grid grid-cols-12 gap-2 justify-center">
          <div
            className="grid grid-cols-12 col-span-12 h-12 text-md font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-3 bg-gray-100 cursor-pointer border"
            onClick={() => navigate('/profile')}
          >
            <h2 className="col-span-12 ">
              {user &&
                'Good ' +
                  (hours < 12 && hours > 3
                    ? 'morning'
                    : hours >= 12 && hours < 18
                    ? 'afternoon'
                    : 'evening') +
                  ', ' +
                  user.name}
            </h2>
          </div>

          <div className="col-span-12 font-semibold text-lg text-lh-dark-blue">
            {checkAccess(['fsa', 'sop', 'fsm'])
              ? 'Orders'
              : checkAccess(['tae'])
              ? 'Leads'
              : ''}
          </div>

          <div
            className="grid grid-cols-12 col-span-3 h-20 text-xs md:text-lg font-bold bg-lh-dark-blue text-white text-center align-middle rounded-lg p-2 pt-2 border-2 border-lh-dark-blue cursor-pointer hover:bg-white hover:text-lh-dark-blue transition-all"
            onClick={() => {
              logEvent(analytics, 'dashboard_click', { object: 'pending' });
              goToOrders('pending');
            }}
          >
            <p className="text-center col-span-12 font-medium -mb-2">Pending</p>
            <p className="text-center col-span-12 text-2xl">{pending}</p>
          </div>

          {!checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-3 h-20 text-xs md:text-lg font-bold bg-lh-light-green text-white text-center align-middle rounded-lg p-2 pt-2 border-2 border-lh-light-green cursor-pointer hover:bg-white hover:text-lh-light-green transition-all"
              onClick={() => {
                logEvent(analytics, 'dashboard_click', {
                  object: 'accepted',
                });
                goToOrders('accepted');
              }}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Accepted
              </p>
              <p className="text-center col-span-12 text-2xl">
                {acceptedOrders}
              </p>
            </div>
          )}
          <div
            className="grid grid-cols-12 col-span-3 h-20 text-xs md:text-lg font-bold bg-lh-light-blue text-white text-center align-middle rounded-lg p-2 pt-2 border-2 border-lh-light-blue cursor-pointer hover:bg-white hover:text-lh-light-blue transition-all"
            onClick={() => {
              logEvent(analytics, 'dashboard_click', {
                object: 'fulfilled',
              });
              goToOrders('fulfilled');
            }}
          >
            <p className="text-center col-span-12 font-medium -mb-2">
              Fulfilled
            </p>
            <p className="text-center col-span-12 text-2xl">{fulfilled}</p>
          </div>
          {!checkAccess(['tae']) && (
            <div
              className="grid grid-cols-12 col-span-3 h-20 text-xs md:text-lg font-bold bg-lh-red text-white text-center align-middle rounded-lg p-2 pt-2 border-2 border-lh-red cursor-pointer hover:bg-white hover:text-lh-red transition-all"
              onClick={() => {
                logEvent(analytics, 'dashboard_click', {
                  object: 'rejected',
                });
                goToOrders('rejected');
              }}
            >
              <p className="text-center col-span-12 font-medium -mb-2">
                Rejected
              </p>
              <p className="text-center col-span-12 text-2xl">
                {rejectedOrders}
              </p>
            </div>
          )}
        </div>
        {checkAccess(['fsa', 'tae', 'sop', 'fsm', 'distributor']) && (
          <div className="flex flex-col md:flex-row justify-between items-center my-4">
            <div className="font-semibold text-lg text-lh-dark-blue flex items-center">
              Performance Dashboard
              <div className="relative flex items-center group">
                <svg
                  className="w-5 h-5 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div className="absolute left-0 flex items-center hidden ml-6 group-hover:flex">
                  <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg shadow-gray-400 rounded-md">
                    Updated every couple of hours.
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 bg-gray-100 px-2 py-1 rounded-md border border-gray-200">
              <input
                type="checkbox"
                onChange={() => setShowFullLastMonth(!showFullLastMonth)}
                id="fullMonth"
              />
              <label className="text-sm" htmlFor="fullMonth">
                SPLM Full Month
              </label>
            </div>
          </div>
        )}

        <div className="w-full grid grid-cols-12 gap-2 justify-center">
          {!checkAccess(['tae']) && (
            <div
              className={
                'grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-2 bg-gray-100 border' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsTargetsCement as any}
                data={dataCementTargets}
              />
            </div>
          )}
          {!checkAccess(['tae']) && (
            <div
              className={
                'grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-2 bg-gray-100 border' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsTargetsMortar as any}
                data={dataMortarTargets}
                className="h-24"
              />
            </div>
          )}
          {!checkAccess(['tae']) && (
            <div className="grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-1 bg-gray-100 border">
              <Bar
                options={optionsOrderVolume as any}
                plugins={[ChartjsPluginStacked100 as any]}
                data={dataOrderVolume}
              />
            </div>
          )}
          {checkAccess(['fsa', 'sop', 'fsm']) && (
            <div
              className={
                'grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-white text-center align-middle rounded-lg p-2 pt-2 bg-gray-100 border' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsTargetsVisits as any}
                data={dataVisitsTargets}
              />
            </div>
          )}
          {checkAccess(['tae', 'fsm', 'sop']) && (
            <div
              className={
                'grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-1 bg-gray-100 border' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsLeadsVolumeTargets as any}
                data={dataLeadsVolumeTargets}
              />
            </div>
          )}
          {checkAccess(['tae']) && (
            <div
              className={
                'grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-2 bg-gray-100 border' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsLeadsTargets as any}
                data={dataLeadsTargets}
              />
            </div>
          )}
          {checkAccess(['tae', 'sop', 'fsm']) && (
            <div
              className={
                'grid grid-cols-12 col-span-12 md:col-span-6 h-36 text-lg font-bold text-lh-dark-blue text-center align-middle rounded-lg p-2 pt-2 bg-gray-100 border' +
                (checkAccess(['sop', 'fsm']) ? ' cursor-pointer' : '')
              }
              onClick={() => {
                return checkAccess(['sop', 'fsm']) ? goToTargets() : null;
              }}
            >
              <Bar
                options={optionsSiteVisitsTargets as any}
                data={dataSiteVisitsTargets}
                className="h-24"
              />
            </div>
          )}
        </div>
      </div>
    </Content>
  );
});

export default Home;
