import { DevTool } from '@hookform/devtools';
import { useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { To } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../components/forms/Confirm';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/Select';
import Content from '../../../components/layout/Content';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Loader from '../../../components/ui/Loader';
import { useSite } from '../../../lib/hooks/use-sites';
import { useSiteOwner } from '../../../lib/hooks/use-siteOwners';
import useInterval from '../../../lib/utils/useInterval';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGeolocation } from '../../../lib/utils/useGeolocation';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Map from '../../../components/map/Map';
import Layers from '../../../components/map/layers/Layers';
import { Location } from '../../../../../global';
import IconUpdateLayer from '../../../components/map/layers/IconUpdateLayer';

import storeImage from '../../../assets/icons/store-solid-white.svg';

type EditSiteParams = 'id';

type EditSiteForm = {
  type: string;
  geohash: string;
  name: string;
  lat: number;
  lng: number;
  accuracy: number;
};

const SITE_TYPES = [
  { key: 'commercial', label: 'Commercial', value: 'commercial' },
  { key: 'private', label: 'Private', value: 'private' },
];

export const EditSite = () => {
  const MIN_ACCURACY = 100;
  const { id } = useParams<EditSiteParams>();
  const navigate = useNavigate();
  const [siteOwnerId, setSiteOwnerId] = useState('');
  const { error: siteOwnerError, loading: siteOwnerLoading } =
    useSiteOwner(siteOwnerId);
  const {
    site,
    error: siteError,
    loading: siteLoading,
    update: siteUpdate,
  } = useSite(id);
  const {
    register,
    formState: { isValid, isDirty, errors, dirtyFields },
    reset,
    handleSubmit,
    control,
    setValue,
    trigger,
  } = useForm<EditSiteForm>();
  const [showSiteAlert, setShowSiteAlert] = useState(false);
  const [showSiteOwnerAlert, setShowSiteOwnerAlert] = useState(false);
  const [showLocationAlert, setShowLocationAlert] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [backString, setBackString] = useState<To>('/sites');
  const [geohashCaptured, setGeoHashCaptured] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(60);
  const {
    coords,
    positionError,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocation({
    userDecisionTimeout: 5000,
    watchPosition: true,
  });
  const [location, setLocation] = useState<Location>();

  const locationErrorMessage = useMemo(() => {
    switch (positionError?.code) {
      case 1: // PERMISSION_DENIED
        return 'Please enable location services following the user manual: https://www.mcomtech.ch/guide';
      case 2: // POSITION_UNAVAILABLE
        return 'Location services are unavailable. Please try again later.';
      case 3: // TIMEOUT
        return 'Location services timed out. Please try again later.';
      case undefined:
        return undefined;
      default:
        return 'Unknown error';
    }
  }, [positionError]);

  const waitingForAccuracy = useMemo(() => {
    if (
      countdown === 0 ||
      !isGeolocationAvailable ||
      !isGeolocationEnabled ||
      positionError
    ) {
      return false;
    }
    return !coords || coords.accuracy > MIN_ACCURACY;
  }, [
    coords,
    MIN_ACCURACY,
    countdown,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  ]);

  const countDownDelay = useMemo(
    () => (countdown === 0 ? null : 1000),
    [countdown]
  );

  useInterval(() => {
    setCountdown(countdown - 1);
  }, countDownDelay);

  const nameOptions: RegisterOptions = {
    required: 'Please enter a name!',
    minLength: {
      value: 3,
      message: 'Please enter a name with at least 3 characters!',
    },
    maxLength: {
      value: 50,
      message: 'The name cannot exceed 50 characters!',
    },
  };

  const channelOptions: RegisterOptions = {
    required: 'Please select a site type!',
  };

  useEffect(() => {
    if (site) {
      setSiteOwnerId(site.siteOwner.id);
    }
  }, [site]);

  useEffect(() => {
    if (site) {
      const values = {
        name: site.name,
      };
      if (site.location) {
        values['lat'] = site.location.lat;
        values['lng'] = site.location.lng;
        values['geohash'] = site.location.geohash;
      }
      if ('type' in site) {
        values['type'] = site.type;
      }
      reset(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    if (siteError) {
      setShowSiteAlert(true);
    } else if (showSiteAlert) {
      setShowSiteAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteError]);

  useEffect(() => {
    if (site) {
      setBackString(`/sites/${site.id}`);
    }
  }, [site, id]);

  const submitHandler = (data: EditSiteForm) => {
    if (isDirty) {
      const updateObj = {};
      if (dirtyFields.name) updateObj['name'] = data.name;
      if (dirtyFields.type) updateObj['type'] = data.type;

      if (
        dirtyFields.geohash ||
        dirtyFields.lat ||
        dirtyFields.lng ||
        dirtyFields.accuracy
      ) {
        updateObj['location'] = {
          geohash: data.geohash,
          lat: data.lat,
          lng: data.lng,
          accuracy: data.accuracy,
        };
      }

      siteUpdate(updateObj);
      navigate(backString, { replace: true });
    }
  };

  const handleOnConfirm = () => {
    setShowConfirmation(false);
    handleSubmit(submitHandler)();
  };

  const handleOnCancel = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (siteOwnerError) {
      setShowSiteOwnerAlert(true);
    }
  }, [siteOwnerError]);

  useEffect(() => {
    if (positionError) {
      setShowLocationAlert(true);
    }
  }, [positionError]);

  const handleCaptureLocation = () => {
    if (coords) {
      const { lat, lng, geohash, accuracy } = coords;
      setLocation({ lat, lng, geohash, accuracy });
      setValue('lat', lat, { shouldDirty: true });
      setValue('lng', lng, { shouldDirty: true });
      setValue('geohash', geohash, { shouldDirty: true });
      setValue('accuracy', accuracy, { shouldDirty: true });
      setGeoHashCaptured(true);
      trigger(['lat', 'lng', 'geohash', 'accuracy']);
    }
  };

  return (
    <Content>
      <Loader show={siteLoading || siteOwnerLoading} />
      <Alert
        message={siteOwnerError && siteOwnerError.message}
        open={showSiteOwnerAlert}
        setOpen={(open) => setShowSiteOwnerAlert(open)}
        title="Error"
      />
      <Alert
        message={siteError && siteError.message}
        open={showSiteAlert}
        setOpen={(open) => setShowSiteAlert(open)}
        title="Error"
      />
      <Alert
        message={positionError && locationErrorMessage}
        open={showLocationAlert}
        setOpen={(open) => setShowLocationAlert(open)}
        title="Error"
      />
      <Confirm
        title="Are you sure you want to save these changes?"
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={setShowConfirmation}
      />
      <Card>
        <Back to={backString} />
        <form
          onSubmit={(event) => event.preventDefault()}
          className="col-span-full flex flex-col"
        >
          <label htmlFor="name">Name</label>
          <Input
            register={register}
            name="name"
            placeholder="Name..."
            error={errors.name}
            options={nameOptions}
          />
          <label htmlFor="type">Site Type</label>
          <Select
            register={register}
            name="type"
            items={SITE_TYPES}
            placeholder="Select type..."
            error={errors.type}
            options={channelOptions}
          />
          {waitingForAccuracy && (
            <div className="col-span-7 col-start-2 flex flex-row justify-center mt-3">
              <FontAwesomeIcon
                icon={faSpinner}
                className="animate-spin h-5 w-5 mr-3"
              ></FontAwesomeIcon>
              <p>Waiting for device location...{countdown} seconds left.</p>
            </div>
          )}
          <Button
            onClick={handleCaptureLocation}
            text={
              geohashCaptured ? 'Re-Capture Location' : 'Capture Site Location'
            }
            color="green"
          />
          <input {...register('lat')} className="hidden" />
          <input {...register('lng')} className="hidden" />
          <input {...register('geohash')} className="hidden" />
          {geohashCaptured && (
            <>
              <p className="text-green-500 font-thin text-sm">
                The location has ben captured!
              </p>
              <Map center={location} className="col-span-full h-56 mt-4">
                <Layers>
                  <IconUpdateLayer
                    oldLocation={site.location}
                    newLocation={location}
                    icon={storeImage}
                    name={'Location'}
                  />
                </Layers>
              </Map>
            </>
          )}
          <Button
            buttonDisabled={!isValid || !isDirty}
            text="Save"
            color="green"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </form>
        <DevTool control={control} />
      </Card>
    </Content>
  );
};
