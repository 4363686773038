import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import { Order } from '../../../../global';
import { createTier2Fulfillment } from '../../lib/db/tier2fulfillments';
import { useOutlets } from '../../lib/hooks/use-outlets';
import { Confirm } from '../forms/Confirm';
import Input from '../forms/Input';
import SearchableSelect from '../forms/SearchableSelect';
import Alert from '../ui/Alert';
import Button from '../ui/Button';
import Loader from '../ui/Loader';
import Modal from '../ui/Modal';
import Select from '../forms/Select';

type FulfillOrderProps = {
  open: boolean;
  order: Order;
  onFulfill?: SubmitHandler<FulfillOrderForm>;
  onFulfillTier2?: SubmitHandler<FulfillOrderForm>;
  setOpen: (boolean) => void;
  confirmText: string;
  buttonText: string;
  tier2?: boolean;
};

type FulfillOrderForm = {
  classic: number;
  supaset: number;
  supafixc0: number;
  supafixc1: number;
  watershield: number;
  deliveredToConstructionSite: boolean;
  //tempProduct1 tempProduct1: number;
  //tempProduct2 tempProduct2: number;
  //tempProduct3 tempProduct3: number;
  //tempProduct4 tempProduct4: number;
};

const FulfillOrder = ({
  open,
  order,
  onFulfill,
  onFulfillTier2,
  setOpen,
  confirmText,
  buttonText,
  tier2,
}: FulfillOrderProps) => {
  const { register, handleSubmit, reset, getValues, control } =
    useForm<FulfillOrderForm>({
      defaultValues: {
        classic: 0,
        supaset: 0,
        supafixc0: 0,
        supafixc1: 0,
        watershield: 0,
        //tempProduct1 tempProduct1: 0,
        //tempProduct2 tempProduct2: 0,
        //tempProduct3 tempProduct3: 0,
        //tempProduct4 tempProduct4: 0,
      },
    });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [block, setBlock] = useState(false);
  const [showOutletsAlert, setShowOutletsAlert] = useState(false);

  const {
    error: outletsError,
    loading: outletsLoading,
    outlets,
  } = useOutlets({
    pageSize: 0,
    active: true,
  });

  const moreThanZero = (): boolean => {
    const classic = getValues('classic');
    const supafixc0 = getValues('supafixc0');
    const supafixc1 = getValues('supafixc1');
    const supaset = getValues('supaset');
    const watershield = getValues('watershield');
    //tempProduct1 const tempProduct1 = getValues('tempProduct1');
    //tempProduct2 const tempProduct2 = getValues('tempProduct2');
    //tempProduct3 const tempProduct3 = getValues('tempProduct3');
    //tempProduct4 const tempProduct4 = getValues('tempProduct4');
    return (
      (isNaN(classic) ? 0 : classic) +
        (isNaN(supafixc0) ? 0 : supafixc0) +
        (isNaN(supafixc1) ? 0 : supafixc1) +
        (isNaN(supaset) ? 0 : supaset) +
        (isNaN(watershield) ? 0 : watershield) >=
      //tempProduct1 (isNaN(tempProduct1) ? 0 : tempProduct1) +
      //tempProduct2 (isNaN(tempProduct2) ? 0 : tempProduct2) +
      //tempProduct3 (isNaN(tempProduct3) ? 0 : tempProduct3) +
      //tempProduct4 (isNaN(tempProduct4) ? 0 : tempProduct4)
      0
    );
  };

  const productOptions: RegisterOptions = {
    valueAsNumber: true,
    min: {
      value: 0,
      message: 'Product cannot be negative!',
    },
    max: {
      value: 10000,
      message: "You can only order 10'000 bags at a time!",
    },
    validate: {
      moreThanZero,
    },
  };

  const handleOnConfirm = () => {
    setBlock(false);
    setShowConfirmation(false);

    if (tier2) {
      handleSubmit(handleFulfillTier2)();
    } else {
      handleSubmit(onFulfill)();
    }
  };

  const handleOnCancel = () => {
    setBlock(false);
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (order)
      reset({
        classic: order.productsDelivered
          ? order.products.classic - order.productsDelivered.classic >= 0
            ? order.products.classic - order.productsDelivered.classic
            : 0
          : order.products.classic,
        supaset: order.productsDelivered
          ? order.products.supaset - order.productsDelivered.supaset >= 0
            ? order.products.supaset - order.productsDelivered.supaset
            : 0
          : order.products.supaset,
        supafixc0: order.productsDelivered
          ? order.products.supafixc0 - order.productsDelivered.supafixc0 >= 0
            ? order.products.supafixc0 - order.productsDelivered.supafixc0
            : 0
          : order.products.supafixc0,
        supafixc1: order.productsDelivered
          ? order.products.supafixc1 - order.productsDelivered.supafixc1 >= 0
            ? order.products.supafixc1 - order.productsDelivered.supafixc1
            : 0
          : order.products.supafixc1,
        watershield: order.productsDelivered
          ? order.products.watershield - order.productsDelivered.watershield >=
            0
            ? order.products.watershield - order.productsDelivered.watershield
            : 0
          : order.products.watershield,
        /* TempProduct1 tempProduct1: order.productsDelivered
          ? order.products.tempProduct1 - order.productsDelivered.tempProduct1 >=
            0
            ? order.products.tempProduct1 - order.productsDelivered.tempProduct1
            : 0
          : order.products.tempProduct1,
        TempProduct 1 */
        /* TempProduct2 tempProduct2: order.productsDelivered
          ? order.products.tempProduct2 - order.productsDelivered.tempProduct2 >=
            0
            ? order.products.tempProduct2 - order.productsDelivered.tempProduct2
            : 0
          : order.products.tempProduct2,
        TempProduct 2 */
        /* TempProduct3 tempProduct3: order.productsDelivered
          ? order.products.tempProduct3 - order.productsDelivered.tempProduct3 >=
            0
            ? order.products.tempProduct3 - order.productsDelivered.tempProduct3
            : 0
          : order.products.tempProduct3,
        TempProduct 3 */
        /* TempProduct4 tempProduct4: order.productsDelivered
          ? order.products.tempProduct4 - order.productsDelivered.tempProduct4 >=
            0
            ? order.products.tempProduct4 - order.productsDelivered.tempProduct4
            : 0
          : order.products.tempProduct4,
        TempProduct 4 */
      });
  }, [order, reset]);

  const handleFulfillTier2 = async (data) => {
    const productsDeliveredTier2 = {
      classic: isNaN(data.classic) ? 0 : data.classic,
      supaset: isNaN(data.supaset) ? 0 : data.supaset,
      supafixc0: isNaN(data.supafixc0) ? 0 : data.supafixc0,
      supafixc1: isNaN(data.supafixc1) ? 0 : data.supafixc1,
      watershield: isNaN(data.watershield) ? 0 : data.watershield,
      //tempProduct1 tempProduct1: isNaN(data.tempProduct1) ? 0 : data.tempProduct1,
      //tempProduct2 tempProduct2: isNaN(data.tempProduct2) ? 0 : data.tempProduct2,
      //tempProduct3 tempProduct3: isNaN(data.tempProduct3) ? 0 : data.tempProduct3,
      //tempProduct4 tempProduct4: isNaN(data.tempProduct4) ? 0 : data.tempProduct4,
    };

    await createTier2Fulfillment({
      outletId: data.outlet.value,
      fulfilledProducts: productsDeliveredTier2,
      outletIdOrdered: order.outlet.id,
      fulfilledAt: Timestamp.now(),
      orderId: order.id,
      deliveredToConstructionSite: data.deliveredToConstructionSite === 'true',
    });

    handleSubmit(onFulfillTier2)();
  };

  return (
    <Modal open={open} setOpen={setOpen} block={block}>
      <Loader show={outletsLoading} />
      <Alert
        message={outletsError && outletsError.message}
        open={showOutletsAlert}
        setOpen={(open) => setShowOutletsAlert(open)}
        title="Error"
      />
      <Confirm
        title={confirmText}
        open={showConfirmation}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        setOpen={(open) => {
          setShowConfirmation(open);
          setBlock(open);
        }}
      />
      <form
        name="fulfillOrderForm"
        onSubmit={handleSubmit(onFulfill)}
        className="grid grid-cols-12"
      >
        <h2 className="col-span-full font-bold">
          Products fulfilled / ordered
        </h2>
        <h3 className="col-span-4 py-1">Classic</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.classic
              : 0)}{' '}
          {' / '}
          {order && order.products.classic}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">Supaset</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.supaset
              : 0)}{' '}
          {' / '}
          {order && order.products.supaset}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">SupafixC0</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.supafixc0
              : 0)}{' '}
          {' / '}
          {order && order.products.supafixc0}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">SupafixC1</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.supafixc1
              : 0)}{' '}
          {' / '}
          {order && order.products.supafixc1}
        </p>
        <p className="col-span-1 py-1">bags</p>
        <h3 className="col-span-4 py-1">Watershield</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.watershield
              : 0)}{' '}
          {' / '}
          {order && order.products.watershield}
        </p>
        <p className="col-span-1 py-1">bags</p>
        {/* TempProduct1 <h3 className="col-span-4 py-1">TempProduct1</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.tempProduct1
              : 0)}{' '}
          {' / '}
          {order && order.products.tempProduct1}
        </p>
        <p className="col-span-1 py-1">bags</p>
        TempProduct1 */}
        {/* TempProduct2 <h3 className="col-span-4 py-1">TempProduct2</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.tempProduct2
              : 0)}{' '}
          {' / '}
          {order && order.products.tempProduct2}
        </p>
        <p className="col-span-1 py-1">bags</p>
        TempProduct2 */}
        {/* TempProduct3 <h3 className="col-span-4 py-1">TempProduct3</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.tempProduct3
              : 0)}{' '}
          {' / '}
          {order && order.products.tempProduct3}
        </p>
        <p className="col-span-1 py-1">bags</p>
        TempProduct3 */}
        {/* TempProduct4 <h3 className="col-span-4 py-1">TempProduct4</h3>
        <p className="col-span-4 col-start-8 mb-4 mr-2 text-right py-1 px-3">
          {order &&
            (order.productsDelivered
              ? order.productsDelivered.tempProduct4
              : 0)}{' '}
          {' / '}
          {order && order.products.tempProduct4}
        </p>
        <p className="col-span-1 py-1">bags</p>
        TempProduct4 */}
        {tier2 && (
          <SearchableSelect
            control={control}
            name="outlet"
            rules={{ required: true }}
            label="Tier 2 outlet"
            placeholder="Select Outlet..."
            values={
              outlets
                ? outlets
                    .filter((outlet) => outlet.id !== order.outlet.id)
                    .map((outlet) => {
                      if (outlet.id !== order.outlet.id) {
                        return {
                          value: outlet.id,
                          label: outlet.name,
                        };
                      } else return null;
                    })
                : []
            }
            className="col-span-12 text-lh-text-black"
          />
        )}
        <h2 className="col-span-full font-bold">Products Delivered</h2>
        <label htmlFor="classic" className="col-span-4 pt-1">
          Classic
        </label>
        <Input
          register={register}
          placeholder="0"
          name="classic"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="classic" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="supaset" className="col-span-4 pt-1">
          Supaset
        </label>
        <Input
          register={register}
          placeholder="0"
          name="supaset"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="supaset" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="supafixc0" className="col-span-4 pt-1">
          Supafix C0
        </label>
        <Input
          register={register}
          placeholder="0"
          name="supafixc0"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="supafixc0" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="supafixc1" className="col-span-4 pt-1">
          Supafix C1
        </label>
        <Input
          register={register}
          placeholder="0"
          name="supafixc1"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="supafixc1" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        <label htmlFor="watershield" className="col-span-4 pt-1">
          Watershield
        </label>
        <Input
          register={register}
          placeholder="0"
          name="watershield"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="watershield" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        {/* TempProduct1 <label htmlFor="tempProduct1" className="col-span-4 pt-1">
          TempProduct1
        </label>
        <Input
          register={register}
          placeholder="0"
          name="tempProduct1"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="tempProduct1" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        TempProduct1 */}
        {/* TempProduct2 <label htmlFor="tempProduct2" className="col-span-4 pt-1">
          TempProduct2
        </label>
        <Input
          register={register}
          placeholder="0"
          name="tempProduct2"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="tempProduct2" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        TempProduct2 */}
        {/* TempProduct3 <label htmlFor="tempProduct3" className="col-span-4 pt-1">
          TempProduct3
        </label>
        <Input
          register={register}
          placeholder="0"
          name="tempProduct3"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="tempProduct3" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        TempProduct3 */}
        {/* TempProduct4 <label htmlFor="tempProduct4" className="col-span-4 pt-1">
          TempProduct4
        </label>
        <Input
          register={register}
          placeholder="0"
          name="tempProduct4"
          type="number"
          options={productOptions}
          className="col-span-4 col-start-8 text-right"
        />
        <label htmlFor="tempProduct4" className="col-spa-1 ml-2 pt-1">
          bags
        </label>
        TempProduct4 */}
      {/* Dropdown with yes or no if delivered to a construction site directly */}
      <label htmlFor="constructionSite" className="col-span-7 mt-2 ml-1">
        Delivered to construction site
      </label>
     <Select
        register={register}
        name="deliveredToConstructionSite"
        items={
          [
            { key: 'no', label: 'No', value: 'false' },
            { key: 'yes', label: 'Yes', value: 'true' },
          ]
        }
        className="col-span-4"
     />
      </form>
      <Button
        text={buttonText}
        color="green"
        className="w-full"
        onClick={() => {
          setBlock(true);
          setShowConfirmation(true);
        }}
      />
    </Modal>
  );
};

export default FulfillOrder;
