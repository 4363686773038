import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import {
  CreateOrderData,
  ProductsType,
  UpdateOrderData,
} from '../../../../global';
import { firestore } from '../utils/firebase';

interface CreateOrder {
  (data: CreateOrderData): Promise<any>;
}
interface UpdateOrder {
  (id: string, data: UpdateOrderData): Promise<void>;
}

interface AcceptOrder {
  (id: string, comment: string): Promise<void>;
}

interface RejectOrder {
  (id: string, comment: string): Promise<void>;
}

interface CancelOrder {
  (id: string, comment: string): Promise<void>;
}

interface FulfillOrder {
  (id: string, productsDelivered: ProductsType, deliveredToConstructionSite: boolean): Promise<void>;
}

interface PartialFulfillOrder {
  (id: string, productsDelivered: ProductsType, deliveredToConstructionSite: boolean): Promise<void>;
}

interface Tier2FulfillOrder {
  (id: string, productsDelivered: ProductsType): Promise<void>;
}

export const createOrder: CreateOrder = async (data) => {
  const ref = collection(firestore, 'orders');
  await addDoc(ref, data);
};

export const updateOrder: UpdateOrder = async (id, data) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, data);
};

export const acceptOrder: AcceptOrder = async (id, comment) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, { status: 'accepted', comment });
};

export const rejectOrder: RejectOrder = async (id, comment) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, { status: 'rejected', comment });
};

export const cancelOrder: CancelOrder = async (id, comment) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, { status: 'cancelled', comment });
};

export const fulfillOrder: FulfillOrder = async (id, productsDelivered, deliveredToConstructionSite) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, {
    deliveredToConstructionSite,
    status: 'fulfilled',
    fulfillmentStatus: 'pendingFulfillment',
    productsDelivered,
  });
};

export const partialFulfillOrder: PartialFulfillOrder = async (
  id,
  productsDelivered,
  deliveredToConstructionSite
) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, {
    fulfillmentStatus: 'pendingPartialFulfillment',
    productsDelivered,
    deliveredToConstructionSite
  });
};

export const tier2FulfillOrder: Tier2FulfillOrder = async (
  id,
  productsDelivered
) => {
  const ref = doc(firestore, 'orders', id);
  await updateDoc(ref, {
    fulfillmentStatus: 'pendingTier2Fulfillment',
    productsDelivered,
  });
};
