import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/ui/Alert';
import Card from '../../../components/ui/Card';
import { useRetailer } from '../../../lib/hooks/use-retailers';
import Button from '../../../components/ui/Button';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import Loader from '../../../components/ui/Loader';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useOutlet, useOutlets } from '../../../lib/hooks/use-outlets';
import { faCircle, faStore } from '@fortawesome/free-solid-svg-icons';
import { sortByStatus } from '../../../lib/utils/helpers';
import Edit from '../../../components/ui/Edit';
import clsx from 'clsx';
import RejectRetailer, {
  RejectRetailerForm,
} from '../../../components/retailer/RejectRetailer';
import {
  RejectOutlet,
  RejectOutletForm,
} from '../../../components/retailer/RejectOutlet';
import RejectRetailerChanges, {
  RejectRetailerChangesForm,
} from '../../../components/retailer/RejectRetailerChanges';
import {
  RejectOutletChanges,
  RejectOutletChangesForm,
} from '../../../components/retailer/RejectOutletChanges';
import Map from '../../../components/map/Map';
import Layers from '../../../components/map/layers/Layers';
import IconUpdateLayer from '../../../components/map/layers/IconUpdateLayer';
import IconLayer from '../../../components/map/layers/IconLayer';

import storeIcon from '../../../assets/icons/store-solid-white.svg';

type OrderDetailsParams = 'id';

const Retailer = () => {
  const checkAccess = useCheckAccess();
  const { id } = useParams<OrderDetailsParams>();
  const navigate = useNavigate();
  const {
    retailer,
    error: retailerError,
    loading: retailerLoading,
    confirmChanges: retailerConfirmChanges,
    confirmRegistration: retailerConfirmRegistration,
    confirmRejection: retailerConfirmRejection,
    confirmChangesRejection: retailerConfirmChangesRejection,
  } = useRetailer(id);
  const {
    outlets,
    error: outletError,
    loading: outletLoading,
    reset: outletReset,
  } = useOutlets({ pageSize: 0, retailerId: id });
  const [selectedOutletId, setSelectedOutletId] = useState<string>();
  const {
    outlet,
    confirmChanges: outletConfirmChanges,
    confirmRegistration: outletConfirmRegistration,
    confirmRejection: outletConfirmRejection,
    confirmChangesRejection: outletConfirmChangesRejection,
  } = useOutlet(selectedOutletId);
  const [showRetailerAlert, setShowRetailerAlert] = useState(false);
  const [showOutletAlert, setShowOutletAlert] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showRejectRetailer, setShowRejectRetailer] = useState<boolean>(false);
  const [showRejectOutlet, setShowRejectOutlet] = useState<boolean>(false);
  const [showRejectRetailerChanges, setShowRejectRetailerChanges] =
    useState<boolean>(false);
  const [showRejectOutletChanges, setShowRejectOutletChanges] =
    useState<boolean>(false);

  useEffect(() => {
    if (retailerError) {
      setShowRetailerAlert(true);
    } else if (showRetailerAlert) {
      setShowRetailerAlert(false);
    }
    return () => {
      setShowRetailerAlert(false);
    };
  }, [retailerError, showRetailerAlert]);

  useEffect(() => {
    if (
      retailer &&
      (retailer.status === 'confirmed' || retailer.status === 'changesRejected')
    ) {
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
    }
  }, [retailer]);

  useEffect(() => {
    if (outletError) {
      setShowOutletAlert(true);
    } else if (showOutletAlert) {
      setShowOutletAlert(false);
    }
    return () => {
      setShowOutletAlert(false);
    };
  }, [outletError, showOutletAlert]);

  // Set initially selected outlet id
  useEffect(() => {
    if (!selectedOutletId && outlets?.length > 0) {
      setSelectedOutletId(outlets[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlets]);

  const outletLocation = useMemo(() => {
    if (!outlet?.location) return undefined;
    return outlet.location;
  }, [outlet]);

  const newOutletLocation = useMemo(() => {
    if (!outlet?.changes?.location) return undefined;
    return outlet.changes.location;
  }, [outlet]);

  const handleSelectOutlet = (event) => {
    setSelectedOutletId(event.target.value);
  };

  const handleConfirmRetailer = async () => {
    await retailerConfirmRegistration(true);
    await outletConfirmRegistration(true);
  };

  const handleRejectRetailer = async (data: RejectRetailerForm) => {
    await retailerConfirmRegistration(false, data.comment);
    await outletConfirmRegistration(false, data.comment);
    // navigate('/retailers', { replace: true });
    setShowRejectRetailer(false);
  };

  const handleConfirmRetailerChanges = async () => {
    let retailerChanges = retailer.changes;
    if (
      outlets.filter(
        (outlet) =>
          outlet.status !== 'confirmed' && outlet.status !== 'changesRejected'
      ).length < 1
    ) {
      retailerChanges = { ...retailerChanges, outletChanged: false };
    }
    await retailerConfirmChanges(true, retailerChanges);
    outletReset();
  };

  const handleRejectRetailerChanges = async (
    data: RejectRetailerChangesForm
  ) => {
    let retailerChanges = retailer.changes;
    if (
      outlets.filter(
        (outlet) =>
          outlet.status !== 'confirmed' && outlet.status !== 'changesRejected'
      ).length < 1
    ) {
      retailerChanges = { ...retailerChanges, outletChanged: false };
    }

    await retailerConfirmChanges(false, retailerChanges, data.comment);
    outletReset();
    setShowRejectRetailerChanges(false);
  };

  const handleConfirmOutlet = async () => {
    await outletConfirmRegistration(true);
    outletReset();
  };

  const handleRejectOutlet = async (data: RejectOutletForm) => {
    await outletConfirmRegistration(false, data.comment);
    outletReset();
    setShowRejectRetailer(false);
  };

  const handleConfirmOutletChanges = async () => {
    await outletConfirmChanges(true, outlet.changes);
    outletReset();
  };

  const handleRejectOutletChanges = async (data: RejectOutletChangesForm) => {
    await outletConfirmChanges(false, outlet.changes, data.comment);
    outletReset();
    setShowRejectOutletChanges(false);
  };

  const handleConfirmRetailerRejection = async () => {
    await retailerConfirmRejection();
    navigate('/retailers', { replace: true });
  };

  const handleConfirmOutletRejection = async () => {
    await outletConfirmRejection();
  };

  const handleConfirmRetailerChangesRejection = async () => {
    await retailerConfirmChangesRejection();
  };

  const handleConfirmOutletChangesRejection = async () => {
    await outletConfirmChangesRejection();
  };

  return (
    <Content>
      <Loader show={retailerLoading || outletLoading} />
      <Alert
        message={retailerError && retailerError.message}
        open={showRetailerAlert}
        setOpen={(open) => setShowRetailerAlert(open)}
        title="Error"
      />
      <Alert
        message={outletError && outletError.message}
        open={showOutletAlert}
        setOpen={(open) => setShowOutletAlert(open)}
        title="Error"
      />
      {checkAccess(['sop']) && (
        <RejectRetailer
          open={showRejectRetailer}
          onReject={handleRejectRetailer}
          setOpen={(open) => setShowRejectRetailer(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectOutlet
          open={showRejectOutlet}
          onReject={handleRejectOutlet}
          setOpen={(open) => setShowRejectOutlet(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectRetailerChanges
          open={showRejectRetailerChanges}
          onReject={handleRejectRetailerChanges}
          setOpen={(open) => setShowRejectRetailerChanges(open)}
        />
      )}
      {checkAccess(['sop']) && (
        <RejectOutletChanges
          open={showRejectOutletChanges}
          onReject={handleRejectOutletChanges}
          setOpen={(open) => setShowRejectOutletChanges(open)}
        />
      )}
      {retailerLoading || retailer ? (
        <Card>
          <div className="grid grid-cols-12">
            <Back to="/retailers" className=" col-span-3" />
            <div className="col-span-6 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {retailer && (
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="mr-2 text-lh-head-black"
                />
              )}
              Retailer
              {retailer && (
                <FontAwesomeIcon
                  icon={faCircle}
                  className={clsx(
                    (retailer.status === 'unconfirmed' ||
                      retailer.status === 'changes') &&
                      'ml-2 text-yellow-500',
                    ((retailer.status === 'confirmed' &&
                      !retailer.outletChanged &&
                      retailer.phoneCorrect) ||
                      (retailer.status === 'changesRejected' &&
                        !retailer.outletChanged &&
                        retailer.phoneCorrect)) &&
                      'ml-2 text-green-600',
                    retailer.status === 'rejected' ||
                      ('phoneCorrect' in retailer &&
                        !retailer.phoneCorrect &&
                        'ml-2 text-red-600'),
                    retailer.outletChanged && 'ml-2 text-yellow-500'
                  )}
                />
              )}
            </div>
            {showEditButton && <Edit className="col-span-3 flex-row-reverse" />}
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Name:
            </p>
            <p className="text-lh-head-black text-lg col-span-8 text-left">
              {retailer && retailer.name}
            </p>
            <p className="text-lh-head-black text-lg col-span-3 col-start-2">
              Phone:
            </p>
            <p
              className={clsx(
                'text-lg col-span-8 text-left text-lh-head-black',
                retailer && !retailer.phoneCorrect && 'text-red-600'
              )}
            >
              {retailer && retailer.phone}
            </p>
            {retailer &&
              (retailer.status === 'confirmed' ||
                retailer.status === 'changesRejected') && (
                <>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Last order:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {retailer && retailer.lastOrderPlacedAt
                      ? dayjs(retailer.lastOrderPlacedAt).format(
                          'DD.MM.YYYY HH:mm'
                        )
                      : 'No orders placed yet'}
                  </p>
                </>
              )}
            {retailer && retailer.status === 'changes' && (
              <>
                {retailer && retailer.changes && retailer.changes.name && (
                  <>
                    <p className="text-lh-head-black text-lg col-span-3 col-start-2 font-bold">
                      New name:{' '}
                    </p>
                    <p className="text-lh-head-black text-lg col-span-8 font-bold">
                      {retailer.changes && retailer.changes.name}
                    </p>
                  </>
                )}
                {retailer && retailer.changes && retailer.changes.phone && (
                  <>
                    <p className="text-lh-head-black text-lg col-span-3 col-start-2 font-bold">
                      New phone:{' '}
                    </p>
                    <p className="text-lh-head-black text-lg col-span-8 font-bold">
                      {retailer.changes && retailer.changes.phone}
                    </p>
                  </>
                )}
                {checkAccess(['sop']) && (
                  <div className="col-span-full flex justify-between">
                    <Button
                      onClick={handleConfirmRetailerChanges}
                      color="green"
                      className="flex-grow"
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => setShowRejectRetailerChanges(true)}
                      color="red"
                      className="flex-grow"
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </>
            )}
            <hr className="border-black mt-2 col-span-full" />
            {outlets && (
              <>
                {outlets.length > 1 ? (
                  <>
                    <div className="col-span-12 grid grid-cols-12 justify-center items-center text-center text-xl font-bold text-lh-head-black">
                      <h1 className="col-span-11 font-bold mt-2 text-center text-xl">
                        <FontAwesomeIcon
                          icon={faStore}
                          className="mr-2 text-lh-head-black"
                        />
                        {outlet && outlet.channelName
                          ? outlet.channelName
                          : 'Outlet'}
                      </h1>
                      {showEditButton && (
                        <Edit
                          to={`/outlets/${outlet?.id}`}
                          className="col-span-1 flex-row-reverse"
                        />
                      )}
                    </div>
                    <select
                      className={
                        'col-span-full text-center cursor-pointer border-2 bg-gray-200 rounded-lg mt-2'
                      }
                      onChange={handleSelectOutlet}
                      value={outlet?.id}
                    >
                      {outlets &&
                        outlets.sort(sortByStatus).map((outlet) => {
                          return (
                            <option key={outlet.id} value={outlet.id}>
                              {outlet.name}
                            </option>
                          );
                        })}
                    </select>
                  </>
                ) : (
                  <div className="col-span-12 grid grid-cols-12 items-center text-center text-xl font-bold text-lh-head-black">
                    <h1 className="col-span-11 font-bold mt-2 text-center text-xl">
                      <FontAwesomeIcon
                        icon={faStore}
                        className="mr-2 text-lh-head-black"
                      />
                      {outlet && outlet.channelName
                        ? outlet.channelName
                        : 'Outlet'}
                    </h1>
                    {showEditButton && (
                      <Edit
                        to={`/outlets/${outlet?.id}`}
                        className="col-span-1 flex-row-reverse"
                      />
                    )}
                  </div>
                )}
                <div className="col-span-full grid grid-cols-12 justify-center mt-2">
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Name:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    FSA:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.primaryFSA.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Distributor:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.primaryDistributor.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Region:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.cluster.region.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Territory:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.cluster.territory.name}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    Cluster:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.cluster.name}
                  </p>
                </div>
                <hr className="border-black mt-2 col-span-full" />
                <h1 className="col-span-12 font-bold mt-2 text-center text-xl">
                  Metrics
                </h1>
                <div className="col-span-full grid grid-cols-12 justify-center mt-2">
                  <p className="text-lh-head-black text-lg col-span-11 col-start-2 font-bold">
                    Volume
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    FSA:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.fulfilledVolumeApp} tons
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    WhatsApp:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.fulfilledVolumeChatbot} tons
                  </p>
                  <p className="text-lh-head-black text-lg col-span-11 col-start-2 font-bold">
                    Orders
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    FSA:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.ordersAmountAppFulfilled}
                  </p>
                  <p className="text-lh-head-black text-lg col-span-3 col-start-2">
                    WhatsApp:
                  </p>
                  <p className="text-lh-head-black text-lg col-span-8 text-left">
                    {outlet && outlet.ordersAmountChatbotFulfilled}
                  </p>
                </div>
                {outlet &&
                  outlet.status === 'changes' &&
                  'changes' in outlet && (
                    <>
                      <hr className="border-black mt-2 col-span-full" />
                      <h1 className="col-span-12 font-bold mt-2 text-center text-xl">
                        Changes
                      </h1>
                      {outlet.changes.name && (
                        <>
                          <p className="col-span-5 col-start-2 font-bold mt-2">
                            New name:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes?.name}
                          </p>
                        </>
                      )}
                      {outlet.changes.primaryDistributor && (
                        <>
                          <p className="col-span-5 col-start-2 font-bold mt-2">
                            New Distributor:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes.primaryDistributor.name}
                          </p>
                        </>
                      )}
                      {outlet.changes.channel && (
                        <>
                          <p className="col-span-5 col-start-2 font-bold mt-2">
                            New Channel:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.channelNameChanges}
                          </p>
                        </>
                      )}
                      {outlet.changes.address && (
                        <>
                          <p className="col-span-5 col-start-2 font-bold mt-2">
                            New Address
                          </p>
                          <p className="col-span-5 col-start-2 mt-2">
                            Street name:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes.address.street}
                          </p>
                          <p className="col-span-5 col-start-2 mt-2">
                            Building number:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes.address.nr}
                          </p>
                          <p className="col-span-5 col-start-2 mt-2">
                            Closest landmark:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes.address.landmark}
                          </p>
                          <p className="col-span-5 col-start-2 mt-2">
                            Area / Neighborhood:
                          </p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes.address.area}
                          </p>
                          <p className="col-span-5 col-start-2 mt-2">City:</p>
                          <p className="col-span-6 font-bold mt-2">
                            {outlet.changes.address.city}
                          </p>
                        </>
                      )}
                      {checkAccess(['sop']) && (
                        <div className="col-span-full flex justify-between mt-6">
                          <Button
                            onClick={handleConfirmOutletChanges}
                            color="green"
                            className="flex-grow"
                          >
                            Confirm
                          </Button>
                          <Button
                            onClick={() => setShowRejectOutletChanges(true)}
                            color="red"
                            className="flex-grow"
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                <Map className="col-span-full h-56 mt-4">
                  <Layers>
                    {newOutletLocation ? (
                      <IconUpdateLayer
                        newLocation={newOutletLocation}
                        oldLocation={outletLocation}
                        icon={storeIcon}
                        name={'Location'}
                      />
                    ) : (
                      <IconLayer
                        location={outletLocation}
                        icon={storeIcon}
                        name={'Location'}
                      />
                    )}
                  </Layers>
                </Map>
                {checkAccess(['sop']) &&
                  outlet &&
                  outlet.status === 'unconfirmed' &&
                  ['confirmed', 'changes'].includes(retailer.status) && (
                    <div className="col-span-full flex justify-between">
                      <Button
                        onClick={handleConfirmOutlet}
                        color="green"
                        className="flex-grow"
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => setShowRejectOutlet(true)}
                        color="red"
                        className="flex-grow"
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                {checkAccess(['fsa']) &&
                  retailer &&
                  retailer.status !== 'rejected' &&
                  outlet &&
                  outlet.status === 'rejected' && (
                    <div className="col-span-full flex flex-col">
                      <h2>
                        The Outlet has been rejected with the following reason:{' '}
                      </h2>
                      <p>{outlet && outlet.rejectionComment}</p>
                      <p>
                        Contact your SOP if you have further questions about
                        this.
                      </p>
                      <Button
                        onClick={handleConfirmOutletRejection}
                        color="red"
                      >
                        Confirm Rejection
                      </Button>
                    </div>
                  )}
                {checkAccess(['fsa']) &&
                  retailer &&
                  retailer.status !== 'changesRejected' &&
                  outlet &&
                  outlet.status === 'changesRejected' && (
                    <div className="col-span-full flex flex-col">
                      <h2>
                        The changes to the Outlet have been rejected with the
                        following reason:{' '}
                      </h2>
                      <p>{outlet && outlet.rejectionComment}</p>
                      <p>
                        Contact your SOP if you have further questions about
                        this.
                      </p>
                      <Button
                        onClick={handleConfirmOutletChangesRejection}
                        color="red"
                      >
                        Confirm Rejection
                      </Button>
                    </div>
                  )}
              </>
            )}
            {retailer &&
              retailer.status === 'unconfirmed' &&
              checkAccess(['sop']) && (
                <div className="col-span-full flex justify-between mt-6">
                  <Button
                    onClick={handleConfirmRetailer}
                    color="green"
                    className="flex-grow"
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => setShowRejectRetailer(true)}
                    color="red"
                    className="flex-grow"
                  >
                    Reject
                  </Button>
                </div>
              )}
            {checkAccess(['fsa']) &&
              retailer &&
              retailer.status === 'rejected' && (
                <div className="col-span-full flex flex-col mt-6">
                  <h2>
                    The Retailer has been rejected with the following reason:{' '}
                  </h2>
                  <p>{retailer && retailer.rejectionComment}</p>
                  <p>
                    Contact your SOP if you have further questions about this.
                  </p>
                  <Button onClick={handleConfirmRetailerRejection} color="red">
                    Confirm Rejection
                  </Button>
                </div>
              )}
            {checkAccess(['fsa']) &&
              retailer &&
              retailer.status === 'changesRejected' && (
                <div className="col-span-full flex flex-col mt-6">
                  <h2>
                    The changes to the Retailer have been rejected with the
                    following reason:{' '}
                  </h2>
                  <p>{retailer && retailer.rejectionComment}</p>
                  <p>
                    Contact your SOP if you have further questions about this.
                  </p>
                  <Button
                    onClick={handleConfirmRetailerChangesRejection}
                    color="red"
                  >
                    Confirm Rejection
                  </Button>
                </div>
              )}
          </div>
        </Card>
      ) : (
        <Card>Retailer not found!</Card>
      )}
    </Content>
  );
};

export default Retailer;
