import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { RegisterOptions, useForm, useWatch } from 'react-hook-form';
import Input from '../../../components/forms/Input';
import { useState } from 'react';

type Props = {
  editable: boolean;
  setStockLevel: any;
  setStockIsDirty: any;
  setStockIsValid: any;
  stockLevel: {
    classicAmount: number;
    classicBuyPrice: number;
    classicSellPrice: number;
    supasetAmount: number;
    supasetBuyPrice: number;
    supasetSellPrice: number;
    supafixc0Amount: number;
    supafixc0BuyPrice: number;
    supafixc0SellPrice: number;
    supafixc1Amount: number;
    supafixc1BuyPrice: number;
    supafixc1SellPrice: number;
    watershieldAmount: number;
    watershieldBuyPrice: number;
    watershieldSellPrice: number;
    //tempProduct1 tempProduct1Amount: number;
    //tempProduct1 tempProduct1BuyPrice: number;
    //tempProduct1 tempProduct1SellPrice: number;
    //tempProduct2 tempProduct2Amount: number;
    //tempProduct2 tempProduct2BuyPrice: number;
    //tempProduct2 tempProduct2SellPrice: number;
    //tempProduct3 tempProduct3Amount: number;
    //tempProduct3 tempProduct3BuyPrice: number;
    //tempProduct3 tempProduct3SellPrice: number;
    //tempProduct4 tempProduct4Amount: number;
    //tempProduct4 tempProduct4BuyPrice: number;
    //tempProduct4 tempProduct4SellPrice: number;
    comp1fAmount: number;
    comp1fBuyPrice: number;
    comp1fSellPrice: number;
    comp1bAmount: number;
    comp1bBuyPrice: number;
    comp1bSellPrice: number;
    comp1xAmount: number;
    comp1xBuyPrice: number;
    comp1xSellPrice: number;
    comp2Amount: number;
    comp2BuyPrice: number;
    comp2SellPrice: number;
    comp3Amount: number;
    comp3BuyPrice: number;
    comp3SellPrice: number;
  };
};

const VisitStep1 = forwardRef((props: Props, ref) => {
  const [formValid, setFormValid] = useState(null);
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: { ...props.stockLevel },
    mode: 'all',
  });

  const classicAmount = useWatch({
    control,
    name: 'classicAmount',
  });

  const classicBuyPrice = useWatch({
    control,
    name: 'classicBuyPrice',
  });

  const classicSellPrice = useWatch({
    control,
    name: 'classicSellPrice',
  });

  const supasetAmount = useWatch({
    control,
    name: 'supasetAmount',
  });

  const supasetBuyPrice = useWatch({
    control,
    name: 'supasetBuyPrice',
  });

  const supasetSellPrice = useWatch({
    control,
    name: 'supasetSellPrice',
  });

  const supafixc0Amount = useWatch({
    control,
    name: 'supafixc0Amount',
  });

  const supafixc0BuyPrice = useWatch({
    control,
    name: 'supafixc0BuyPrice',
  });

  const supafixc0SellPrice = useWatch({
    control,
    name: 'supafixc0SellPrice',
  });

  const supafixc1Amount = useWatch({
    control,
    name: 'supafixc1Amount',
  });

  const supafixc1BuyPrice = useWatch({
    control,
    name: 'supafixc1BuyPrice',
  });

  const supafixc1SellPrice = useWatch({
    control,
    name: 'supafixc1SellPrice',
  });

  const watershieldAmount = useWatch({
    control,
    name: 'watershieldAmount',
  });

  const watershieldBuyPrice = useWatch({
    control,
    name: 'watershieldBuyPrice',
  });

  const watershieldSellPrice = useWatch({
    control,
    name: 'watershieldSellPrice',
  });

  /* tempProduct1
  const tempProduct1Amount = useWatch({
    control,
    name: 'tempProduct1Amount',
  });

  const tempProduct1BuyPrice = useWatch({
    control,
    name: 'tempProduct1BuyPrice',
  });

  const tempProduct1SellPrice = useWatch({
    control,
    name: 'tempProduct1SellPrice',
  });
  tempProduct1 */

  /* tempProduct2
  const tempProduct2Amount = useWatch({
    control,
    name: 'tempProduct2Amount',
  });

  const tempProduct2BuyPrice = useWatch({
    control,
    name: 'tempProduct2BuyPrice',
  });

  const tempProduct2SellPrice = useWatch({
    control,
    name: 'tempProduct2SellPrice',
  });

  tempProduct2 */

  /* tempProduct3
  const tempProduct3Amount = useWatch({
    control,
    name: 'tempProduct3Amount',
  });

  const tempProduct3BuyPrice = useWatch({
    control,
    name: 'tempProduct3BuyPrice',
  });

  const tempProduct3SellPrice = useWatch({
    control,
    name: 'tempProduct3SellPrice',
  });

  tempProduct3 */

  /* tempProduct4
  const tempProduct4Amount = useWatch({
    control,
    name: 'tempProduct4Amount',
  });

  const tempProduct4BuyPrice = useWatch({
    control,
    name: 'tempProduct4BuyPrice',
  });

  const tempProduct4SellPrice = useWatch({
    control,
    name: 'tempProduct4SellPrice',
  });

  tempProduct4 */

  const comp1fAmount = useWatch({
    control,
    name: 'comp1fAmount',
  });

  const comp1fBuyPrice = useWatch({
    control,
    name: 'comp1fBuyPrice',
  });

  const comp1fSellPrice = useWatch({
    control,
    name: 'comp1fSellPrice',
  });

  const comp1bAmount = useWatch({
    control,
    name: 'comp1bAmount',
  });

  const comp1bBuyPrice = useWatch({
    control,
    name: 'comp1bBuyPrice',
  });

  const comp1bSellPrice = useWatch({
    control,
    name: 'comp1bSellPrice',
  });

  const comp1xAmount = useWatch({
    control,
    name: 'comp1xAmount',
  });

  const comp1xBuyPrice = useWatch({
    control,
    name: 'comp1xBuyPrice',
  });

  const comp1xSellPrice = useWatch({
    control,
    name: 'comp1xSellPrice',
  });

  const comp2Amount = useWatch({
    control,
    name: 'comp2Amount',
  });

  const comp2BuyPrice = useWatch({
    control,
    name: 'comp2BuyPrice',
  });

  const comp2SellPrice = useWatch({
    control,
    name: 'comp2SellPrice',
  });

  const comp3Amount = useWatch({
    control,
    name: 'comp3Amount',
  });

  const comp3BuyPrice = useWatch({
    control,
    name: 'comp3BuyPrice',
  });

  const comp3SellPrice = useWatch({
    control,
    name: 'comp3SellPrice',
  });

  const priceOptionsClassic: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      classicAmount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsSupaset: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      supasetAmount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsSupafixC0: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      supafixc0Amount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsSupafixC1: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      supafixc1Amount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsWatershield: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      watershieldAmount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsComp1F: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      comp1fAmount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsComp1B: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      comp1bAmount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsComp1X: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      comp1xAmount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsComp2: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      comp2Amount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const priceOptionsComp3: RegisterOptions = {
    valueAsNumber: true,
    validate: (value) =>
      comp3Amount === 0 ||
      value > 1000 ||
      'Price needs to be higher than 1000!',
    max: {
      value: 29999,
      message: 'Price needs to be lower than 30000!',
    },
  };

  const amountOptions: RegisterOptions = {
    valueAsNumber: true,
    required: 'Amount is required!',
  };

  useEffect(() => {
    const values = getValues();
    if (
      isValid &&
      Object.keys(values).find(
        (value) => !isNaN(values[value]) && values[value] !== undefined
      ) &&
      ((classicAmount === 0 &&
        isNaN(classicBuyPrice) &&
        isNaN(classicSellPrice)) ||
        (classicAmount && classicBuyPrice && classicSellPrice) ||
        ((classicAmount === undefined || isNaN(classicAmount)) &&
          (classicBuyPrice === undefined || isNaN(classicBuyPrice)) &&
          (classicSellPrice === undefined || isNaN(classicSellPrice)))) &&
      ((supasetAmount === 0 &&
        isNaN(supasetBuyPrice) &&
        isNaN(supasetSellPrice)) ||
        (supasetAmount && supasetBuyPrice && supasetSellPrice) ||
        ((supasetAmount === undefined || isNaN(supasetAmount)) &&
          (supasetBuyPrice === undefined || isNaN(supasetBuyPrice)) &&
          (supasetSellPrice === undefined || isNaN(supasetSellPrice)))) &&
      ((supafixc0Amount === 0 &&
        isNaN(supafixc0BuyPrice) &&
        isNaN(supafixc0SellPrice)) ||
        (supafixc0Amount && supafixc0BuyPrice && supafixc0SellPrice) ||
        ((supafixc0Amount === undefined || isNaN(supafixc0Amount)) &&
          (supafixc0BuyPrice === undefined || isNaN(supafixc0BuyPrice)) &&
          (supafixc0SellPrice === undefined || isNaN(supafixc0SellPrice)))) &&
      ((supafixc1Amount === 0 &&
        isNaN(supafixc1BuyPrice) &&
        isNaN(supafixc1SellPrice)) ||
        (supafixc1Amount && supafixc1BuyPrice && supafixc1SellPrice) ||
        ((supafixc1Amount === undefined || isNaN(supafixc1Amount)) &&
          (supafixc1BuyPrice === undefined || isNaN(supafixc1BuyPrice)) &&
          (supafixc1SellPrice === undefined || isNaN(supafixc1SellPrice)))) &&
      ((watershieldAmount === 0 &&
        isNaN(watershieldBuyPrice) &&
        isNaN(watershieldSellPrice)) ||
        (watershieldAmount && watershieldBuyPrice && watershieldSellPrice) ||
        ((watershieldAmount === undefined || isNaN(watershieldAmount)) &&
          (watershieldBuyPrice === undefined || isNaN(watershieldBuyPrice)) &&
          (watershieldSellPrice === undefined ||
            isNaN(watershieldSellPrice)))) &&
      /* tempProduct1
      ((tempProduct1Amount && tempProduct1BuyPrice && tempProduct1SellPrice) ||
        ((tempProduct1Amount === undefined || isNaN(tempProduct1Amount)) &&
          (tempProduct1BuyPrice === undefined || isNaN(tempProduct1BuyPrice)) &&
          (tempProduct1SellPrice === undefined || isNaN(tempProduct1SellPrice)))) &&
      tempProduct1 */
      /* tempProduct2
      ((tempProduct2Amount && tempProduct2BuyPrice && tempProduct2SellPrice) ||
        ((tempProduct2Amount === undefined || isNaN(tempProduct2Amount)) &&
          (tempProduct2BuyPrice === undefined || isNaN(tempProduct2BuyPrice)) &&
          (tempProduct2SellPrice === undefined || isNaN(tempProduct2SellPrice)))) &&
      tempProduct2 */
      /* tempProduct3
      ((tempProduct3Amount && tempProduct3BuyPrice && tempProduct3SellPrice) ||
        ((tempProduct3Amount === undefined || isNaN(tempProduct3Amount)) &&
          (tempProduct3BuyPrice === undefined || isNaN(tempProduct3BuyPrice)) &&
          (tempProduct3SellPrice === undefined || isNaN(tempProduct3SellPrice)))) &&
      tempProduct3 */
      /* tempProduct4
      ((tempProduct4Amount && tempProduct4BuyPrice && tempProduct4SellPrice) ||
        ((tempProduct4Amount === undefined || isNaN(tempProduct4Amount)) &&
          (tempProduct4BuyPrice === undefined || isNaN(tempProduct4BuyPrice)) &&
          (tempProduct4SellPrice === undefined || isNaN(tempProduct4SellPrice)))) &&
      tempProduct4 */
      ((comp1fAmount === 0 &&
        isNaN(comp1fBuyPrice) &&
        isNaN(comp1fSellPrice)) ||
        (comp1fAmount && comp1fBuyPrice && comp1fSellPrice) ||
        ((comp1fAmount === undefined || isNaN(comp1fAmount)) &&
          (comp1fBuyPrice === undefined || isNaN(comp1fBuyPrice)) &&
          (comp1fSellPrice === undefined || isNaN(comp1fSellPrice)))) &&
      ((comp1bAmount === 0 &&
        isNaN(comp1bBuyPrice) &&
        isNaN(comp1bSellPrice)) ||
        (comp1bAmount && comp1bBuyPrice && comp1bSellPrice) ||
        ((comp1bAmount === undefined || isNaN(comp1bAmount)) &&
          (comp1bBuyPrice === undefined || isNaN(comp1bBuyPrice)) &&
          (comp1bSellPrice === undefined || isNaN(comp1bSellPrice)))) &&
      ((comp1xAmount === 0 &&
        isNaN(comp1xBuyPrice) &&
        isNaN(comp1xSellPrice)) ||
        (comp1xAmount && comp1xBuyPrice && comp1xSellPrice) ||
        ((comp1xAmount === undefined || isNaN(comp1xAmount)) &&
          (comp1xBuyPrice === undefined || isNaN(comp1xBuyPrice)) &&
          (comp1xSellPrice === undefined || isNaN(comp1xSellPrice)))) &&
      ((comp2Amount === 0 && isNaN(comp2BuyPrice) && isNaN(comp2SellPrice)) ||
        (comp2Amount && comp2BuyPrice && comp2SellPrice) ||
        ((comp2Amount === undefined || isNaN(comp2Amount)) &&
          (comp2BuyPrice === undefined || isNaN(comp2BuyPrice)) &&
          (comp2SellPrice === undefined || isNaN(comp2SellPrice))))
    ) {
      props.setStockIsDirty(true);
      props.setStockIsValid(true);
      setFormValid(true);
    } else if (
      isValid &&
      (classicAmount === undefined || isNaN(classicAmount)) &&
      (classicBuyPrice === undefined || isNaN(classicBuyPrice)) &&
      (classicSellPrice === undefined || isNaN(classicSellPrice)) &&
      (supasetAmount === undefined || isNaN(supasetAmount)) &&
      (supasetBuyPrice === undefined || isNaN(supasetBuyPrice)) &&
      (supasetSellPrice === undefined || isNaN(supasetSellPrice)) &&
      (supafixc0Amount === undefined || isNaN(supafixc0Amount)) &&
      (supafixc0BuyPrice === undefined || isNaN(supafixc0BuyPrice)) &&
      (supafixc0SellPrice === undefined || isNaN(supafixc0SellPrice)) &&
      (supafixc1Amount === undefined || isNaN(supafixc1Amount)) &&
      (supafixc1BuyPrice === undefined || isNaN(supafixc1BuyPrice)) &&
      (supafixc1SellPrice === undefined || isNaN(supafixc1SellPrice)) &&
      (watershieldAmount === undefined || isNaN(watershieldAmount)) &&
      (watershieldBuyPrice === undefined || isNaN(watershieldBuyPrice)) &&
      (watershieldSellPrice === undefined || isNaN(watershieldSellPrice)) &&
      /* tempProduct1
      (tempProduct1Amount === undefined || isNaN(tempProduct1Amount)) &&
      (tempProduct1BuyPrice === undefined || isNaN(tempProduct1BuyPrice)) &&
      (tempProduct1SellPrice === undefined || isNaN(tempProduct1SellPrice)) &&
      tempProduct1 */
      /* tempProduct2
      (tempProduct2Amount === undefined || isNaN(tempProduct2Amount)) &&
      (tempProduct2BuyPrice === undefined || isNaN(tempProduct2BuyPrice)) &&
      (tempProduct2SellPrice === undefined || isNaN(tempProduct2SellPrice)) &&
      tempProduct2 */
      /* tempProduct3
      (tempProduct3Amount === undefined || isNaN(tempProduct3Amount)) &&
      (tempProduct3BuyPrice === undefined || isNaN(tempProduct3BuyPrice)) &&
      (tempProduct3SellPrice === undefined || isNaN(tempProduct3SellPrice)) &&
      tempProduct3 */
      /* tempProduct4
      (tempProduct4Amount === undefined || isNaN(tempProduct4Amount)) &&
      (tempProduct4BuyPrice === undefined || isNaN(tempProduct4BuyPrice)) &&
      (tempProduct4SellPrice === undefined || isNaN(tempProduct4SellPrice)) &&
      tempProduct4 */
      (comp1fAmount === undefined || isNaN(comp1fAmount)) &&
      (comp1fBuyPrice === undefined || isNaN(comp1fBuyPrice)) &&
      (comp1fSellPrice === undefined || isNaN(comp1fSellPrice)) &&
      (comp1bAmount === undefined || isNaN(comp1bAmount)) &&
      (comp1bBuyPrice === undefined || isNaN(comp1bBuyPrice)) &&
      (comp1bSellPrice === undefined || isNaN(comp1bSellPrice)) &&
      (comp1xAmount === undefined || isNaN(comp1xAmount)) &&
      (comp1xBuyPrice === undefined || isNaN(comp1xBuyPrice)) &&
      (comp1xSellPrice === undefined || isNaN(comp1xSellPrice)) &&
      (comp2Amount === undefined || isNaN(comp2Amount)) &&
      (comp2BuyPrice === undefined || isNaN(comp2BuyPrice)) &&
      (comp2SellPrice === undefined || isNaN(comp2SellPrice))
    ) {
      props.setStockIsDirty(false);
      setFormValid(true);
    } else {
      props.setStockIsValid(false);
      props.setStockIsDirty(true);
      setFormValid(false);
    }
  }, [
    classicAmount,
    classicBuyPrice,
    classicSellPrice,
    comp1bAmount,
    comp1bBuyPrice,
    comp1bSellPrice,
    comp1fAmount,
    comp1fBuyPrice,
    comp1fSellPrice,
    comp1xAmount,
    comp1xBuyPrice,
    comp1xSellPrice,
    comp2Amount,
    comp2BuyPrice,
    comp2SellPrice,
    getValues,
    isValid,
    props,
    supafixc0Amount,
    supafixc0BuyPrice,
    supafixc0SellPrice,
    supafixc1Amount,
    supafixc1BuyPrice,
    supafixc1SellPrice,
    supasetAmount,
    supasetBuyPrice,
    supasetSellPrice,
    watershieldAmount,
    watershieldBuyPrice,
    watershieldSellPrice,
    //tempProduct1 tempProduct1Amount,
    //tempProduct1 tempProduct1BuyPrice,
    //tempProduct1 tempProduct1SellPrice,
    //tempProduct2 tempProduct2Amount,
    //tempProduct2 tempProduct2BuyPrice,
    //tempProduct2 tempProduct2SellPrice,
    //tempProduct3 tempProduct3Amount,
    //tempProduct3 tempProduct3BuyPrice,
    //tempProduct3 tempProduct3SellPrice,
    //tempProduct4 tempProduct4Amount,
    //tempProduct4 tempProduct4BuyPrice,
    //tempProduct4 tempProduct4SellPrice,
  ]);

  useImperativeHandle(ref, () => ({
    saveStock() {
      doSaveStock();
    },
  }));

  const doSaveStock = () => {
    handleSubmit(props.setStockLevel)();
  };

  return (
    <>
      <div className="grid grid-cols-9 col-span-9 ">
        <div className="grid grid-cols-12 col-span-9 content-center ">
          <p className={'col-span-12 text-center mt-4 font-semibold'}>
            Capture Stock
          </p>
          <p className={'col-span-3 text-left mt-2 mb-4 font-semibold'}>
            Product
          </p>
          <p className={'col-span-3 text-center mt-2 mb-4 font-semibold'}>
            Stock (bags)
          </p>
          <p className={'col-span-3 text-center mt-2 mb-4 font-semibold'}>
            Buy price (₦)
          </p>
          <p className={'col-span-3 text-center mt-2 mb-4 font-semibold'}>
            Sell price (₦)
          </p>
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Classic
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="classicAmount"
            placeholder="0"
            error={errors.classicAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsClassic}
            type="number"
            name="classicBuyPrice"
            placeholder="0"
            error={errors.classicBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsClassic}
            type="number"
            name="classicSellPrice"
            placeholder="0"
            error={errors.classicSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Supaset
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="supasetAmount"
            placeholder="0"
            error={errors.supasetAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsSupaset}
            type="number"
            name="supasetBuyPrice"
            placeholder="0"
            error={errors.supasetBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsSupaset}
            type="number"
            name="supasetSellPrice"
            placeholder="0"
            error={errors.supasetSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Supafix C0
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="supafixc0Amount"
            placeholder="0"
            error={errors.supafixc0Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsSupafixC0}
            type="number"
            name="supafixc0BuyPrice"
            placeholder="0"
            error={errors.supafixc0BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsSupafixC0}
            type="number"
            name="supafixc0SellPrice"
            placeholder="0"
            error={errors.supafixc0SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Supafix C1
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="supafixc1Amount"
            placeholder="0"
            error={errors.supafixc1Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsSupafixC1}
            type="number"
            name="supafixc1BuyPrice"
            placeholder="0"
            error={errors.supafixc1BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsSupafixC1}
            type="number"
            name="supafixc1SellPrice"
            placeholder="0"
            error={errors.supafixc1SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Watershield
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="watershieldAmount"
            placeholder="0"
            error={errors.watershieldAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsWatershield}
            type="number"
            name="watershieldBuyPrice"
            placeholder="0"
            error={errors.watershieldBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsWatershield}
            type="number"
            name="watershieldSellPrice"
            placeholder="0"
            error={errors.watershieldSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          {/* tempProduct1
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            TempProduct1
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="tempProduct1Amount"
            placeholder="0"
            error={errors.tempProduct1Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct1BuyPrice"
            placeholder="0"
            error={errors.tempProduct1BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct1SellPrice"
            placeholder="0"
            error={errors.tempProduct1SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          tempProduct1 */}
          {/* tempProduct2
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            TempProduct2
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="tempProduct2Amount"
            placeholder="0"
            error={errors.tempProduct2Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct2BuyPrice"
            placeholder="0"
            error={errors.tempProduct2BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct2SellPrice"
            placeholder="0"
            error={errors.tempProduct2SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          tempProduct2 */}
          {/* tempProduct3
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            TempProduct3
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="tempProduct3Amount"
            placeholder="0"
            error={errors.tempProduct3Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct3BuyPrice"
            placeholder="0"
            error={errors.tempProduct3BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct3SellPrice"
            placeholder="0"
            error={errors.tempProduct3SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          tempProduct3 */}
          {/* tempProduct4
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            TempProduct4
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="tempProduct4Amount"
            placeholder="0"
            error={errors.tempProduct4Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct4BuyPrice"
            placeholder="0"
            error={errors.tempProduct4BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptions}
            type="number"
            name="tempProduct4SellPrice"
            placeholder="0"
            error={errors.tempProduct4SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          tempProduct4 */}
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Comp 1 F
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp1fAmount"
            placeholder="0"
            error={errors.comp1fAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp1F}
            type="number"
            name="comp1fBuyPrice"
            placeholder="0"
            error={errors.comp1fBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp1F}
            type="number"
            name="comp1fSellPrice"
            placeholder="0"
            error={errors.comp1fSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Comp 1 B
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp1bAmount"
            placeholder="0"
            error={errors.comp1bAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp1B}
            type="number"
            name="comp1bBuyPrice"
            placeholder="0"
            error={errors.comp1bBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp1B}
            type="number"
            name="comp1bSellPrice"
            placeholder="0"
            error={errors.comp1bSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>
            Comp 1 X
          </p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp1xAmount"
            placeholder="0"
            error={errors.comp1xAmount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp1X}
            type="number"
            name="comp1xBuyPrice"
            placeholder="0"
            error={errors.comp1xBuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp1X}
            type="number"
            name="comp1xSellPrice"
            placeholder="0"
            error={errors.comp1xSellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>Comp 2</p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp2Amount"
            placeholder="0"
            error={errors.comp2Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp2}
            type="number"
            name="comp2BuyPrice"
            placeholder="0"
            error={errors.comp2BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp2}
            type="number"
            name="comp2SellPrice"
            placeholder="0"
            error={errors.comp2SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <p className={'col-span-3 text-left mb-2 font-normal mt-1'}>Comp 3</p>
          <Input
            register={register}
            options={amountOptions}
            type="number"
            name="comp3Amount"
            placeholder="0"
            error={errors.comp3Amount}
            disabled={props.editable}
            className={'col-span-3  pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp3}
            type="number"
            name="comp3BuyPrice"
            placeholder="0"
            error={errors.comp3BuyPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
          <Input
            register={register}
            options={priceOptionsComp3}
            type="number"
            name="comp3SellPrice"
            placeholder="0"
            error={errors.comp3SellPrice}
            disabled={props.editable}
            className={'col-span-3 pr-2 text-right mx-1 mb-2 font-normal'}
          />
        </div>
        {!formValid && (
          <p
            className={
              'col-span-12 pr-2 text-center text-red-500 mx-1 mb-2 font-normal'
            }
          >
            Please enter values for each product. If stock is 0, enter 0. If the
            stock is 0 you cannot enter a price.
          </p>
        )}
      </div>
    </>
  );
});

export default VisitStep1;
