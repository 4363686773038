import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { Associate } from '../../../../global';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import clsx from 'clsx';
import { useMemo } from 'react';

type RetailerProps = {
  retailer: Associate;
};

const Retailer = ({ retailer }: RetailerProps) => {
  const navigate = useNavigate();
  const checkAccess = useCheckAccess();

  const retailerStatusMessage = useMemo(() => {
    const { actionRequired, hasWhatsApp } = retailer;

    if (checkAccess(['sop', 'fsm'])) {
      if (actionRequired) {
        return 'Action required!';
      }

      if (!hasWhatsApp) {
        return 'No WhatsApp!';
      }
    }

    if (checkAccess(['fsa'])) {
      if (actionRequired) {
        return 'Waiting for approval!';
      }

      if (!hasWhatsApp) {
        return 'No WhatsApp!';
      }
    }

    return null;
  }, [retailer]); // eslint-disable-line react-hooks/exhaustive-deps

  const retailerStatusColor = clsx([
    'flex justify-between mx-2 mb-2 h-18 list-none shadow-md rounded-lg pb-4 px-2 pt-2 cursor-pointer bg-white',
    retailerStatusMessage !== null && 'border-2',
    retailerStatusMessage === 'Action required!' && 'border-yellow-500',
    retailerStatusMessage === 'Waiting for approval!' && 'border-yellow-500',
    retailerStatusMessage === 'No WhatsApp!' && 'border-red-500',
  ]);

  return (
    <li
      className={retailerStatusColor}
      onClick={() => {
        navigate(`/retailers/${retailer.id}`);
      }}
    >
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-6">
          <p className="text-lh-head-black align-middle col-span-4 font-bold">
            <FontAwesomeIcon
              icon={faUserCircle}
              className="text-lh-head-black"
            />
            {` ${retailer.name}`}
          </p>
          <p className="mt-1 text-lh-head-black">{` ${retailer.phone}`}</p>
        </div>
        <p className="col-span-5 text-right self-center mr-2 text-lh-head-black font-semibold">
          {retailerStatusMessage}
        </p>
        <p className="col-span-1 text-right self-center  text-lh-head-black font-bold mr-4">
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-lh-head-black font-bold align-middle"
          />
        </p>
      </div>
    </li>
  );
};

export default Retailer;
