import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import ListItem from '../ui/list/ListItem';
import { InventoryType } from '../../../../global';
import numeral from 'numeral';
import clsx from 'clsx';

type InventoryProps = {
  inventory: InventoryType;
};

const Inventory = ({ inventory }: InventoryProps) => {
  const navigate = useNavigate();

  const border = clsx(!inventory.initialSet && 'border-2 border-yellow-500');

  return (
    <ListItem
      onClick={() => navigate(`/inventories/${inventory.id}`)}
      className={border}
    >
      <div className="self-center col-span-5 text-lh-head-black">
        <FontAwesomeIcon icon={faUserCircle} /> {inventory.distributor.name}
      </div>
      <div className="grid grid-cols-12 col-span-4 h-48">
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Classic
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.classic).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Supaset
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.supaset).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Supafix C0
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.supafixc0).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Supafix C1
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.supafixc1).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          Watershield
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.watershield).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        {/* TempProduct1 
         <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          TempProduct1
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.tempProduct1).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        TempProduct1 */}
        {/* TempProduct2
         <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          TempProduct2
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.tempProduct2).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        TempProduct2 */}
        {/* TempProduct3 
         <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          TempProduct3
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.tempProduct3).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        TempProduct3 */}
        {/* TempProduct4
         <p className="col-span-6 text-left pt-3 truncate overflow-hidden">
          TempProduct4
        </p>
        <p className="col-span-6 text-lh-text-black text-right pt-3 truncate overflow-hidden">
          {numeral(inventory.current.tempProduct4).format('0.[0]')}
          <span className="text-gray-500 font-light ml-1">tons</span>
        </p>
        TempProduct4 */}
      </div>
      <p className="self-center text-right pr-4 pt-1 col-span-1 text-lh-head-black">
        <FontAwesomeIcon icon={faAngleRight} />
      </p>
    </ListItem>
  );
};

export default Inventory;
